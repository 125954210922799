import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { COLORS } from "okthink-core";
import { TagText } from "../../design/type";
import { timeOfDayColors } from "okthink-core/logic/getTimeOfDayBorder";

const Component = styled(TagText)`
  color: ${(props) => props.color || "black"};
  padding: 1px 4px;
  border-radius: 4px;
  background-color: ${COLORS.BASE2.value};
`;

export const Tag = ({ tag }) => {
  const tagColorMap =
    useSelector((state) =>
      state.notebook ? state.notebook.tagColorMap : null
    ) || {};

  const strippedTag = tag.replace("#", "");
  const color =
    timeOfDayColors[strippedTag] || tagColorMap[strippedTag] || "white";

  return <Component color={color}>{tag}</Component>;
};
