import { splitTagsAndUrls } from "./splitTagsAndUrls";

const tagMatch = /(#\w+)/gi;
const urlMatch = /(https?:\/\/[^\s]+)/g;

const identity = (x) => x;

// written by chatgpt!
export const highlightTagsAndUrls = (
  text,
  renderTag,
  renderLink = identity
) => {
  const items = splitTagsAndUrls(text);

  const replaced = items.map((item) => {
    if (item.match(tagMatch)) {
      return renderTag(item);
    } else if (item.match(urlMatch)) {
      return renderLink(item);
    }

    return item;
  });

  return replaced;
};
