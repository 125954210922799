import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { H1 } from "../design/type";
import { TimelineShortcuts } from "../screens/WarPath/TimelineShortcuts";
import { CircleProgressBar } from "./CircleProgressBar";

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #13071eee;
  border-radius: 8px;
  padding: 20px;
  min-width: 0px;
`;

const PanelHeader = styled(H1)`
  margin: 0;
  font-family: "Lustria", serif;
  font-size: ${(props) => (props.highlight ? 22 : 18)}px;
  color: ${(props) =>
    props.highlight ? COLORS.TODAY_HEADER.value : COLORS.PANEL_HEADER.value};
  margin-bottom: 10px;
`;

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(230 230 217 / 70%);
  margin-bottom: 20px;
  padding-bottom: 10px;
  align-items: flex-end;
`;

export const Panel = ({
  title,
  highlight = false,
  children,
  className,
  todayColumn,
  percentage,
}) => {
  return (
    <Component className={className}>
      <HeaderBar>
        <PanelHeader highlight={highlight}>{title}</PanelHeader>

        {percentage && <CircleProgressBar percentage={percentage} />}
      </HeaderBar>
      {todayColumn && <TimelineShortcuts />}

      {children}
    </Component>
  );
};
