import React from "react";
import styled from "styled-components";

const Component = styled.div`
  color: white;
  font-family: "Baloo Paaji";
  font-weight: 400;
  font-size: 32px;
  line-height: 78.2%;
  letter-spacing: -0.06em;

  display: inline-grid;
  grid-template-areas: "text";
  place-items: center;

  & > *,
  &::before,
  &::after {
    grid-area: text;
  }

  &::before {
    grid-area: text;
    content: attr(data-text);
    -webkit-text-stroke: 10px black;
  }
`;

export const StickerText = ({ label, className }) => {
  return (
    <Component className={className} data-text={label}>
      <span>{label}</span>
    </Component>
  );
};
