import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import updateLocale from "dayjs/plugin/updateLocale";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(isToday);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});
