import React, { useState } from "react";
import styled from "styled-components";
import { DebugTaskList } from "../screens/WarPath/DebugTaskList";
import { ColorCheat } from "./ColorCheat";
import { TypeCheat } from "./TypeCheat";
import { ReduxCheat } from "./ReduxCheat";
import { WarpathCheat } from "./WarpathCheat";

import { TabButton } from "../components/TabButton";
import { COLORS } from "okthink-core";

const Component = styled.div`
  background-color: ${COLORS.FOOTER_BACKGROUND.value};
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-top: 1px solid #ffffff1a;
`;

const Header = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

const Body = styled.div`
  max-height: 400px;
  display: flex;
  flex: 1;
  overflow: scroll;
  padding: 3px;
`;

export const CheatSheets = ({ on }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!on) {
    return null;
  }

  const items = [
    { label: "Color", Component: ColorCheat }, //
    { label: "Type", Component: TypeCheat }, //
    { label: "TaskLists", Component: DebugTaskList }, //
    { label: "Redux", Component: ReduxCheat }, //
    { label: "Warpath", Component: WarpathCheat }, //
  ];

  const CheatSheet = items[selectedIndex].Component;

  return (
    <Component>
      <Header>
        <Buttons>
          {items.map((item, index) => {
            return (
              <TabButton
                key={item.label}
                onClick={(e) => {
                  setSelectedIndex(index);
                  e.preventDefault();
                }}
                label={item.label}
                active={index === selectedIndex}
              />
            );
          })}
        </Buttons>
      </Header>
      <Body>
        <CheatSheet />
      </Body>
    </Component>
  );
};
