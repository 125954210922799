import styled from "styled-components";
import { COLORS } from "okthink-core";
import { Tag } from "../WarPath/Tag";
import { highlightTagsAndUrls } from "okthink-core/logic/highlightTagsAndUrls";

const Component = styled.div`
  padding: 0;
  line-height: 20px;
  white-space: pre-wrap;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 8px;
  width: fit-content;
  color: ${(props) => (props.color ? props.color : COLORS.WHITE.value)};
`;

const Link = styled.a`
  color: ${COLORS.LINK.value};
`;

export const HabitLabel = ({ text, tags, tagColorMap, color }) => {
  const highlightedText = highlightTagsAndUrls(
    text,
    (tag) => {
      return <Tag tag={tag} key={tag} />;
    },
    (url) => {
      return (
        <Link key={url} href={url} target="_blank" rel="noreferrer">
          {url}
        </Link>
      );
    }
  );

  return <Component color={color}>{highlightedText}</Component>;
};
