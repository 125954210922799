import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: null,
  email: null,
  emailVerified: false,
  displayName: null,
  isAnonymous: false,
  photoUrl: null,
  createdAt: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { payload: user } = action;
      const {
        uid,
        email,
        emailVerified,
        displayName,
        isAnonymous,
        photoUrl,
        createdAt,
      } = user;

      state.uid = uid;
      state.email = email;
      state.emailVerified = emailVerified;
      state.displayName = displayName;
      state.isAnonymous = isAnonymous;
      state.photoUrl = photoUrl;
      state.createdAt = createdAt;
    },
    clearUser: () => initialState,
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
