import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ErrorItem } from "./ErrorItem";
import { TodoItem } from "./TodoItem";
import { H3, Text } from "../../design/type";
import { useSelector } from "react-redux";
import { useTaskList } from "okthink-core/logic/useTaskList";
import { COLORS } from "okthink-core";
import get from "lodash/get";
import { PlaceholderItem } from "./PlaceholderItem";
import { createSelector } from "@reduxjs/toolkit";
import { DebugLabel } from "../../dev/DebugLabel";
import { TagBar } from "../../components/TagBar";
import { TaskListHelp } from "./TaskListHelp";
import { Panel } from "../../components/Panel";

// I'm not entirely sure why flex-basis was needed here
// to enable the overflow scroll.
const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  flex-basis: 100px;
  gap: 5px;
`;

const Footer = styled.div`
  min-height: 50px;
`;

const Info = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Title = styled(H3)`
  margin-bottom: 10px;
`;

const selectWorkspaceData = createSelector(
  [
    (state, columnPosition) => {
      const { cursor } = state.workspace;
      const { y, x } = cursor;
      const activeY = columnPosition === x ? y : null;
      return activeY;
    },
    (state) => {
      return state.workspace.cursor.editing;
    },
    (state) => {
      return state.workspace.viewOptions.hideComplete;
    },

    (state, columnPosition) => {
      return state.workspace.columns[columnPosition].columnId;
    },
    (state, columnPosition) => {
      return state.workspace.columns[columnPosition].tagIndex || 0;
    },
  ],
  (activeY, editing, hideComplete, taskListId, tagIndex) => {
    return {
      activeY,
      editing,
      hideComplete,
      taskListId,
      tagIndex,
    };
  },
  {
    memoizeOptions: {
      maxSize: 10, // this selector will get called with parameters, that's why
    },
  }
);

export const TaskList = ({ columnPosition, currentDateKey, title }) => {
  const itemsRef = useRef({});

  // the taskList could be null?
  const workspaceData = useSelector((state) => {
    return selectWorkspaceData(state, columnPosition);
  });

  const { activeY, editing, hideComplete, taskListId, tagIndex } =
    workspaceData;

  const taskListData = useTaskList({
    taskListId,
    tagIndex,
  });

  const {
    taskList,
    numCompleted,
    totalItems,
    totalTodoItems,
    numPunted,
    visibleOrder,
    tagSummary,
  } = taskListData;

  /* -- Redux --*/

  //scrolling
  const activeId = visibleOrder[activeY];
  useEffect(() => {
    if (!activeId) {
      return;
    }
    const itemsMap = itemsRef.current;
    const activeNode = itemsMap[activeId];

    if (activeNode) {
      activeNode.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeId]);

  const handleRef = (habitId, node) => {
    const map = itemsRef.current;
    if (node) {
      map[habitId] = node;
    } else {
      delete map[habitId];
    }
  };

  if (!taskList) {
    return <Component>&nbsp;</Component>;
  }

  const { itemsMap } = taskList;

  const actions = get(taskList, "meta.dataErrors.actions", null);

  const hasOrphans = actions && actions.orphanedDocs.length;

  const listStatusLabel = `${numCompleted}/${totalItems}`;

  const percentage = {
    numCompleted,
    numTotal: totalTodoItems,
  };

  const { tag } = tagSummary[tagIndex];
  let tagTitle = "";

  if (tag !== "all" && tag !== "none") {
    tagTitle = `#${tag}`;
  }

  const todayColumn = currentDateKey !== undefined;

  return (
    <Panel
      title={title}
      highlight={todayColumn}
      todayColumn={todayColumn}
      percentage={percentage}
    >
      <Component>
        <TagBar tagSummary={tagSummary} selectedIndex={tagIndex} />
        <TaskListHelp />
        <Title>{tagTitle}</Title>
        {hideComplete && (
          <Text color={COLORS.YELLOW.value}>
            {numCompleted} completed item{numCompleted > 1 ? "s" : ""} and{" "}
            {numPunted} punted item{numPunted > 1 ? "s" : ""} hidden
          </Text>
        )}
        <DebugLabel text={`${currentDateKey || ""} ${taskListId}`} />
        {visibleOrder.length === 0 ? (
          <PlaceholderItem isActive={activeY === 0} />
        ) : null}
        {visibleOrder.map((itemId, index) => {
          const item = itemsMap[itemId];
          if (!item) {
            return <ErrorItem key={itemId} missingId={itemId} />;
          }

          if (hideComplete && item.todo && item.value) {
            return null;
          }

          const active = activeY === index;
          const itemEditing = active && editing;
          return (
            <TodoItem
              id={itemId}
              key={itemId}
              taskListId={taskListId}
              itemId={item.meta.id}
              isActive={active}
              ref={(node) => {
                handleRef(itemId, node);
              }}
              editing={itemEditing}
            />
          );
        })}
        {hasOrphans
          ? actions.orphanedDocs.map((item) => {
              return <ErrorItem key={item.id} orphanedItem={item} />;
            })
          : null}
        <Footer />
      </Component>
    </Panel>
  );
};
