import { getDoc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { processTaskList } from "./processTaskList";
import {
  getTaskListRef,
  getItemsQuery,
  getTaskListByDateKeyQuery,
  getNotebookRef,
  getTaskListsRef,
  getTasksListsQuery,
  getHabitsQuery,
} from "./firebase-utils";

export const reqTaskList = async (uid, listId) => {
  try {
    const taskListRef = getTaskListRef(uid, listId);
    const itemsQuery = getItemsQuery(uid, listId);

    const taskListDoc = await getDoc(taskListRef);
    const itemSnapshots = await getDocs(itemsQuery);
    const data = processTaskList(taskListDoc, itemSnapshots);
    return data;
  } catch (e) {
    debugger;
    console.error(e);
    return null;
  }
};

export const reqTaskListAtDateKey = async (uid, dateKey) => {
  const query = getTaskListByDateKeyQuery(uid, dateKey);
  const querySnapshot = await getDocs(query);

  if (querySnapshot.empty) {
    console.log("no taskList found", dateKey);
    return null;
  }

  if (querySnapshot.docs.length > 1) {
    console.warn("more than one document for dateKey", dateKey);
  }

  const taskListDoc = querySnapshot.docs[0];
  const { id: taskListId } = taskListDoc;

  const itemsQuery = getItemsQuery(uid, taskListId);

  const itemSnapshots = await getDocs(itemsQuery);
  const data = processTaskList(taskListDoc, itemSnapshots);
  return data;
};

export const reqDateKeyTaskListId = async (uid, dateKey) => {
  const taskList = await reqTaskListAtDateKey(uid, dateKey);
  if (!taskList) {
    return null;
  }
  return taskList.meta.id;
};

export const reqNotebook = async (uid) => {
  const docRef = getNotebookRef(uid);
  const notebookDoc = await getDoc(docRef);

  const query = getHabitsQuery(uid);
  const habitsSnap = await getDocs(query);

  const habitsMap = {};
  habitsSnap.forEach((habit) => {
    habitsMap[habit.id] = {
      ...habit.data(),
      meta: {
        id: habit.id,
        path: habit.path,
      },
    };
  });

  const notebookData = {
    ...notebookDoc.data(),
    habitsMap,
  };

  return notebookData;
};

export const reqWeek = async (uid, dateKeys) => {
  const taskListsRef = getTaskListsRef(uid);
  const q = query(taskListsRef, where("dateKey", "in", dateKeys));
  const daysSnapshots = await getDocs(q);
  const data = [];

  for (let i = 0; i < daysSnapshots.size; i++) {
    const taskListDoc = daysSnapshots.docs[i];
    const { id } = taskListDoc;

    const itemsQuery = getItemsQuery(uid, id);
    const itemSnapshots = await getDocs(itemsQuery);
    const processedTaskList = processTaskList(taskListDoc, itemSnapshots);
    data.push(processedTaskList);
  }

  return data;
};

export const syncWeekBackground = async ({ uid, weekKey, path }) => {
  const notebookRef = getNotebookRef(uid);

  const pathKey = `weeklyBackgrounds.${weekKey}`;

  // direct key write
  return await updateDoc(notebookRef, { [pathKey]: path });
};

export const getAllTaskLists = async (uid) => {
  const query = getTasksListsQuery(uid);
  const taskListSnaps = await getDocs(query);
  const taskLists = [];
  taskListSnaps.forEach((taskList) => {
    taskLists.push({
      ...taskList.data(),
      meta: {
        id: taskList.id,
        path: taskList.path,
      },
    });
  });
  return taskLists;
};
