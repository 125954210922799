import { getItem, toggleItem, togglePuntItem } from "./warpathSlice";
import { SoundEffects } from "./soundEffects";
import { getIncompleteItemEntry } from "./incompletes/getIncompleteItemEntry";
import { getSelectedDateKey } from "./timeline/getSelectedDateKey";
import { updateRecentIncompletes } from "../operations/updateRecentIncompletes";
import { WarpathDelegate } from "./warpathDelegate";

export const IncompletesDelegate = (store) => {
  const warpathDelegate = WarpathDelegate(store);
  return {
    insertAtCursor: () => {
      console.log("unsupported");
    },
    moveItemUp: () => {
      console.log("unsupported");
    },
    moveItemDown: () => {
      console.log("unsupported");
    },
    toggleItem: ({ columnId, itemId }) => {
      // ok so this is not great, we can't depend on taskListId here
      // cheating here
      const itemEntry = getIncompleteItemEntry(
        store.getState().incompletes,
        itemId
      );

      const { taskListId } = itemEntry;
      if (taskListId) {
        store.dispatch(toggleItem({ taskListId, itemId }));
        SoundEffects.done();
        const dateKey = getSelectedDateKey(store.getState().timeline);
        updateRecentIncompletes(dateKey);
      } else {
        console.warn(`incomplete toggleItem called but ${itemId} not found`);
      }
    },
    deleteItem: ({ columnId, itemId, item }) => {
      const itemEntry = getIncompleteItemEntry(
        store.getState().incompletes,
        itemId
      );
      const { taskListId } = itemEntry;
      if (taskListId) {
        console.log("--deleteItem--", taskListId, itemId, item);
        warpathDelegate.deleteItem({ columnId: taskListId, itemId, item });

        const dateKey = getSelectedDateKey(store.getState().timeline);
        updateRecentIncompletes(dateKey);
      } else {
        console.warn(`incomplete deleteItem called but ${itemId} not found`);
      }
    },
    getColumnSize: () => {
      const { incompletes } = store.getState();
      const { summary } = incompletes;

      const size = summary.reduce((acc, nextIncompletes) => {
        return acc + nextIncompletes.incompleteIds.length;
      }, 0);

      return size;
    },
    getItemAt: (taskListId, y) => {
      const { incompletes, warpath } = store.getState();
      const { itemOrder } = incompletes;
      const itemEntry = itemOrder[y];
      if (!itemEntry) {
        return { itemId: null, item: null };
      }

      const { itemId, taskListId: tlid } = itemEntry;
      const item = getItem(warpath, tlid, itemId);

      return { itemId, item };
    },
    getColumn: (columnId) => {
      return null;
    },
    puntItem: (badTaskListId, itemId) => {
      const itemEntry = getIncompleteItemEntry(
        store.getState().incompletes,
        itemId
      );

      const { taskListId } = itemEntry;
      store.dispatch(togglePuntItem({ taskListId, itemId }));
      const dateKey = getSelectedDateKey(store.getState().timeline);
      updateRecentIncompletes(dateKey);
    },
  };
};
