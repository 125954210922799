import React from "react";
import styled from "styled-components";
import { signInWithGoogle } from "okthink-core/data/auth";
import { H3 } from "../design/type";
import { Button } from "./Button";
import { COLORS } from "okthink-core";

const Component = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.div`
  width: 400px;
  height: 100px;
  padding: 100px 10px;
  background-color: ${COLORS.BASE2.value};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
`;

export const Login = () => {
  return (
    <Component>
      <Box>
        <H3>Login to okthink</H3>
        <Button onClick={signInWithGoogle} label="Sign in with Google" />
      </Box>
    </Component>
  );
};
