import "okthink-core/firstImport";
import "okthink-core/data/firebase";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Design } from "./design/Design";
import { store } from "okthink-core/logic/store";
import { KeyEngine } from "okthink-core/logic/keyEngine";
import styled from "styled-components";
import { Home } from "./Home";

KeyEngine.setStore(store);

const Component = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

function App() {
  return (
    <Provider store={store}>
      <Component>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/design" element={<Design />} />
          </Routes>
        </BrowserRouter>
      </Component>
    </Provider>
  );
}

export default App;
