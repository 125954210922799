import { setIncompletesSummary } from "../logic/incompletes/incompletesSlice";
import { store } from "../logic/store";
import { getRecentIncompletesSummary } from "../logic/warpath/getRecentIncompletesSummary";

export const updateRecentIncompletes = async (dateKey) => {
  const { incompletes } = store.getState();
  const { rangeStart, rangeEnd } = incompletes;

  const summary = getRecentIncompletesSummary(
    store.getState().warpath,
    dateKey,
    rangeStart,
    rangeEnd
  );
  store.dispatch(setIncompletesSummary({ summary }));
};
