import { syncTaskListV2 } from "./syncTaskList";

export const syncDirtyTaskLists = async (store, uid) => {
  const { taskLists } = store.getState().warpath;
  for (const taskList of Object.values(taskLists)) {
    const { dirty, id: taskListId } = taskList.meta;
    if (dirty) {
      await syncTaskListV2({ store, taskListId, uid });
    }
  }
};
