import { useSelector } from "react-redux";
import styled from "styled-components";
import { COLORS } from "okthink-core";

const Label = styled.div`
  font-size: 10px;
  color: ${COLORS.PURPLE.value};
`;

export const DebugLabel = ({ text }) => {
  const showDebug = useSelector((state) => {
    return state.workspace.viewOptions.showDebug;
  });

  if (showDebug) {
    return <Label>{text}</Label>;
  }
  return null;
};
