import { getTags } from "../getTags";

export const makeItem = (id, text, value, todo = true) => {
  const tags = getTags(text);
  return {
    meta: {
      id,
    },
    text,
    todo,
    value,
    tags,
  };
};
