import { createSlice } from "@reduxjs/toolkit";

export const DATE_COLUMN_INDEX = 1;
const BACKLOG_COLUMN_INDEX = 0;

const initialState = {
  cursor: {
    x: DATE_COLUMN_INDEX,
    y: 0,
    editing: false,
  },
  editor: {
    draft: "",
  },
  columns: [
    { columnId: "BACKLOG", type: "taskList" },
    { columnId: null, type: "taskList" },
  ],
  keyStrokes: [],
  viewOptions: {
    hideComplete: false,
    showHelp: false,
    helpLevel: 0,
    focusMode: false,
    showSettings: false,
    showDebug: false,
    showDevGuide: false,
  },
};

export const getColumnTagIndex = (state, columnId) => {
  const column = state.columns.find((column) => {
    return column.columnId === columnId;
  });

  return column.tagIndex || 0;
};

const HELP_LEVELS = 3;

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSafeY: (state, action) => {
      const { y } = state.cursor;
      const { maxY } = action.payload;
      const nextY = Math.min(y, maxY);
      state.cursor.y = nextY;
    },
    cursorMove: (state, action) => {
      if (state.cursor.editing) {
        return;
      }
      const { x, y } = action.payload;
      state.cursor.x = x;
      state.cursor.y = y;
    },
    cursorY: (state, action) => {
      const { y } = action.payload;
      state.cursor.y = y;
    },
    cursorLeft: (state, action) => {
      if (state.cursor.editing) {
        return;
      }
      const { x, y } = state.cursor;
      const { maxY } = action.payload;
      const nextX = Math.max(x - 1, 0);
      const nextY = Math.min(y, maxY);
      state.cursor.x = nextX;
      state.cursor.y = nextY;
    },
    cursorRight: (state, action) => {
      if (state.cursor.editing) {
        return;
      }

      const { maxY } = action.payload;
      const maxRight = state.columns.length;
      const { x, y } = state.cursor;
      const nextX = Math.min(x + 1, maxRight);
      const nextY = Math.min(y, maxY);

      state.cursor.x = nextX;
      state.cursor.y = nextY;
    },
    cursorUp: (state, action) => {
      if (state.cursor.editing) {
        return;
      }
      const { x, y } = state.cursor;
      const nextY = Math.max(y - 1, 0);
      state.cursor.x = x;
      state.cursor.y = nextY;
    },
    cursorDown: (state, action) => {
      if (state.cursor.editing) {
        return;
      }
      const { x, y } = state.cursor;
      const { maxY } = action.payload;
      const nextY = Math.min(y + 1, maxY);
      state.cursor.x = x;
      state.cursor.y = nextY;
    },
    cursorDownAndEditing: (state, action) => {
      const { y } = state.cursor;
      const { maxY } = action.payload;
      state.cursor.y = Math.min(y + 1, maxY);
      state.cursor.editing = true;
    },
    setEditing: (state, action) => {
      const editing = action.payload;
      state.cursor.editing = editing;
    },
    setEditorDraft: (state, action) => {
      const { value } = action.payload;
      state.editor.draft = value;
    },
    clearEditorDraft: (state, action) => {
      state.editor.draft = "";
    },
    toggleEditing: (state) => {
      state.cursor.editing = !state.cursor.editing;
    },
    cycleHelpLevel: (state) => {
      const { helpLevel } = state.viewOptions;
      state.viewOptions.helpLevel = (helpLevel + 1) % HELP_LEVELS;
    },
    setColumnId: (state, action) => {
      const { columnIndex, columnId } = action.payload;

      const column = state.columns[columnIndex];
      column.columnId = columnId;
      column.tagIndex = 0;
      if (state.cursor.x === columnIndex) {
        state.cursor.y = 0;
      }
    },
    pushKeyStroke: (state, action) => {
      const keyStroke = action.payload;
      state.keyStrokes.push(keyStroke);
    },
    clearKeyStrokes: (state, action) => {
      state.keyStrokes = [];
    },
    toggleHideComplete: (state) => {
      state.viewOptions.hideComplete = !state.viewOptions.hideComplete;
    },
    clearViewOptionsAndSetY: (state, action) => {
      const { y } = action.payload;
      state.viewOptions.hideComplete = false;
      state.cursor.y = y;
    },
    moveColumnTagIndex: (state, action) => {
      const { columnId, maxLength, direction } = action.payload;

      const column = state.columns.find((column) => {
        return column.columnId === columnId;
      });

      if (column) {
        const tagIndex = column.tagIndex || 0;
        let newIndex = (tagIndex + direction) % maxLength;
        if (newIndex < 0) {
          newIndex = maxLength - 1;
        }

        column.tagIndex = newIndex;
      }
    },
    setBacklogTaskListId: (state, action) => {
      const { backlogTaskListId } = action.payload;

      state.columns[BACKLOG_COLUMN_INDEX].columnId = backlogTaskListId;
    },
    toggleFocus: (state, action) => {
      state.viewOptions.focusMode = !state.viewOptions.focusMode;

      if (state.viewOptions.focusMode) {
        state.cursor.x = DATE_COLUMN_INDEX;
      }
    },
    toggleShowSettings: (state, action) => {
      state.viewOptions.showSettings = !state.viewOptions.showSettings;

      if (state.viewOptions.showSettings) {
        state.cursor.x = 0;
        state.cursor.y = 0;
      } else {
        state.cursor.x = DATE_COLUMN_INDEX;
        state.cursor.y = 0;
      }
    },
    toggleShowDebug: (state, action) => {
      state.viewOptions.showDebug = !state.viewOptions.showDebug;
    },
    toggleShowDevGuide: (state, action) => {
      state.viewOptions.showDevGuide = !state.viewOptions.showDevGuide;
    },
  },
  extraReducers: {
    "warpath/replaceTaskListId": (state, action) => {
      const { tempTaskListId, newTaskListId } = action.payload;
      const { columns } = state;

      const columnToUpdate = columns.find(
        (column) => column.columnId === tempTaskListId
      );

      columnToUpdate.columnId = newTaskListId;
    },
  },
});

export const {
  setSafeY,
  cursorY,
  cursorLeft,
  cursorRight,
  cursorUp,
  cursorDown,
  cursorDownAndEditing,
  cursorMove,
  setEditing,
  setEditorDraft,
  clearEditorDraft,
  toggleEditing,
  setColumnId,
  pushKeyStroke,
  clearKeyStrokes,
  toggleHideComplete,
  clearViewOptionsAndSetY,
  setBacklogTaskListId,
  cycleHelpLevel,
  toggleFocus,
  toggleShowSettings,
  toggleShowDebug,
  toggleShowDevGuide,
  moveColumnTagIndex,
} = workspaceSlice.actions;

export const workspaceReducer = workspaceSlice.reducer;
