import styled from "styled-components";

export const emojis = {
  bexley: "🐶",
  panchi: "🤷‍♀️",
  awesome: "🤩",
  confront: "💪",
  okthink: "⭕️",
  important: "🐸",
  fun: "😎",
  tada: "🎉",
  finance: "💰",
  raf: "🐢",
  apt: "🏠",
  education: "📚",
  organization: "🧹",
  "5K": "👟",
};

const Emoji = styled.div`
  font-size: 14px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
`;

export const TagEmoji = ({ tags = [] }) => {
  if (tags.length === 0) {
    return null;
  }

  const foundEmojis = tags
    .map((tag) => {
      return emojis[tag];
    })
    .filter((item) => {
      return !!item;
    });

  if (foundEmojis.length === 0) {
    return null;
  }

  return (
    <>
      {foundEmojis.map((emoji) => {
        return <Emoji>{emoji}</Emoji>;
      })}
    </>
  );
};
