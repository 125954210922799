import { COLORS } from "okthink-core";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Tag } from "../screens/WarPath/Tag";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
  flex-basis: 1px;
  max-height: 70px;
  min-height: 30px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const getTagBackground = (props) => {
  if (props.isCompleted) {
    return "transparent";
  }

  return "rgba(200, 255, 200, .05)";
};

const TagItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 4px;
  align-items: center;
  background-color: ${getTagBackground};
  margin-right: 4px;
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.selected ? COLORS.PURPLER.value : "transparent")};
  margin-top: 4px;
  opacity: ${(props) => (props.isCompleted && !props.selected ? 0.8 : 1)};
`;

const TagCount = styled.div`
  font-size: 10px;
  color: ${COLORS.WHITE.value};
  margin-left: 3px;
  opacity: 0.8;
`;

export const TagBar = ({ tagSummary, selectedIndex }) => {
  const selectedTagRef = useRef(null);

  useEffect(() => {
    if (selectedTagRef.current) {
      selectedTagRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedIndex]);

  return (
    <Component>
      {tagSummary.map((tagItem, index) => {
        const { tag, count, completed, punted } = tagItem;
        const isCompleted = completed + punted === count;

        const puntedPart = punted > 0 ? `+${punted}` : "";

        let completedIcon = "";

        if (isCompleted && punted === 0) {
          completedIcon = "🎯";
        } else if (isCompleted && punted > 0) {
          completedIcon = "🏁";
        }

        const label = `${completed}${puntedPart}/${count}${completedIcon}`;

        return (
          <TagItem
            selected={index === selectedIndex}
            isCompleted={isCompleted}
            key={tag}
            ref={index === selectedIndex ? selectedTagRef : null}
          >
            <Tag tag={tag} />
            <TagCount>{label}</TagCount>
          </TagItem>
        );
      })}
    </Component>
  );
};
