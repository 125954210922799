import { checkData } from "./firebase-fixes";

export const processTaskList = (taskListDoc, itemSnapshots) => {
  const docData = taskListDoc.data();
  const itemsMap = {};
  itemSnapshots.forEach((docSnap) => {
    itemsMap[docSnap.id] = {
      ...docSnap.data(),
      meta: {
        id: docSnap.id,
        path: docSnap.ref.path,
      },
    };
  });

  const data = {
    ...docData,
    meta: {
      id: taskListDoc.id,
      path: taskListDoc.ref.path,
    },
    itemsMap,
  };

  const dataErrors = checkData(data);
  data.meta.dataErrors = dataErrors;
  return data;
};
