import { createSlice } from "@reduxjs/toolkit";

const DATE_KEY_RANGE_START = -1;
const DATE_KEY_RANGE_END = -5;
const initialState = {
  summary: [],
  itemOrder: [],
  rangeStart: DATE_KEY_RANGE_START,
  rangeEnd: DATE_KEY_RANGE_END,
};

export const incompletesSlice = createSlice({
  name: "incompletes",
  initialState,
  reducers: {
    setIncompletesSummary: (state, action) => {
      const { summary } = action.payload;
      state.summary = summary;

      const itemOrder = [];
      summary.forEach((incompleteDay) => {
        const { taskListId, incompleteIds } = incompleteDay;
        incompleteIds.forEach((itemId) => {
          itemOrder.push({
            taskListId,
            itemId,
          });
        });
      });

      state.itemOrder = itemOrder;
    },
  },
});

export const { setIncompletesSummary } = incompletesSlice.actions;
export const incompletesReducer = incompletesSlice.reducer;
