import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { dateKeyAdd } from "okthink-core/logic/dateKeyAdd";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-start;
  min-height: 20px;
`;

const BOX_SIZE = 6;
const RADIUS = 3;

const StreakBox = styled.div`
  width: ${BOX_SIZE}px;
  height: ${BOX_SIZE}px;
  margin-right: 2px;
  border-radius: ${RADIUS}px;
  background-color: ${(props) => (props.done ? COLORS.GREEN.value : "#393447")};
  opacity: ${(props) => (props.done ? 1 : 0.5)};
`;

const StreakValue = styled.div`
  margin-right: 4px;
  font-size: 10px;
  text-align: right;
  color: ${COLORS.WHITE.value};
`;

const calculateStreak = (completions, startDateKey) => {
  let streak = 0;
  let streaking = false;
  let dateKey = dateKeyAdd(startDateKey, -1);

  do {
    const habitCompletion = completions[dateKey];
    streaking = habitCompletion && habitCompletion.done === true;
    if (streaking) {
      streak++;
      dateKey = dateKeyAdd(dateKey, -1);
    }
  } while (streaking);

  // check if that also includes today
  const todayCompletion = completions[startDateKey];
  if (todayCompletion && todayCompletion.done) {
    streak++;
  }

  return streak;
};

const selectHabitStreak = createSelector(
  [
    (state, habitId) => {
      return state.notebook.habitsMap[habitId];
    },
    (state, habitId, dateKey) => {
      return dateKey;
    },
  ],
  (habit, dateKey) => {
    const { completions = [] } = habit;

    if (!completions) {
      debugger;
    }
    const streak = calculateStreak(completions, dateKey);
    const history = getHabitHistory(completions, dateKey, 7);
    return { streak, history };
  }
);

const getHabitHistory = (completions, startDateKey, length) => {
  const history = Array(length)
    .fill(0)
    .map((item, index) => {
      const dateKey = dateKeyAdd(startDateKey, -index);
      const habitCompletion = completions[dateKey];
      const done = habitCompletion && habitCompletion.done === true;
      return {
        dateKey,
        done,
      };
    })
    .reverse();

  return history;
};

// follows fibbonacci: 1, 2, 3, 5, 8, 13, 21,
const getStreakEmoji = (days) => {
  if (days <= 1) {
    return "🐣";
  } else if (days <= 2) {
    return "👍";
  } else if (days <= 3) {
    return "👏";
  } else if (days <= 5) {
    return "💪";
  } else if (days <= 8) {
    return "🤩";
  } else if (days <= 13) {
    return "🔥";
  } else if (days <= 21) {
    return "⭐️";
  } else {
    return "🎩";
  }
};

export const HabitStreak = ({ habitId, startDateKey }) => {
  const { streak, history } = useSelector((state) => {
    return selectHabitStreak(state, habitId, startDateKey);
  });

  const emoji = getStreakEmoji(streak);
  const streakLabel = streak > 0 ? `${streak} ${emoji}` : "";

  return (
    <Component>
      <StreakValue>{streakLabel}</StreakValue>
      {history.map((historyItem) => {
        const { dateKey, done } = historyItem;
        return <StreakBox key={dateKey} done={done} />;
      })}
    </Component>
  );
};
