import React, { useEffect } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { TimelineDay } from "./TimelineDay";
import { TimelineWeek } from "./TimelineWeek";
import { getWeather } from "okthink-core/data/weather";
import { useSelector, useDispatch } from "react-redux";
import { setWeatherForecast } from "okthink-core/logic/weatherSlice";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding-bottom: 30px;
  margin-bottom: -30px;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 20;
`;

const DaysRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 36px;
  padding-top: 8px;
  padding-bottom: 24px;

  background-color: #281c38f0;
`;

export const Timeline = () => {
  const selectedIndex = useSelector((state) => state.timeline.selectedIndex);
  const days = useSelector((state) => state.timeline.days);
  const weatherForecast = useSelector((state) => state.weather.forecast);
  const dispatch = useDispatch();

  useEffect(() => {
    const loader = async () => {
      try {
        const forecast = await getWeather("Boston", "MA");
        dispatch(setWeatherForecast(forecast));
      } catch (e) {
        console.log("unable to get weather");
        console.log(e);
      }
    };
    loader();
  }, [dispatch]);

  const today = dayjs();

  return (
    <Component>
      <DaysRow>
        {days.map((day, index) => {
          const dateKey = day.format("YYYY-MM-DD");
          const weather = weatherForecast ? weatherForecast[dateKey] : null;
          const dayNumber = parseInt(day.format("d"));
          const isCurrentWeek = day.format("w") === today.format("w");

          if (dayNumber === 1) {
            return (
              <React.Fragment key={dateKey}>
                <TimelineWeek
                  key={`w${dateKey}`}
                  day={day}
                  isCurrentWeek={isCurrentWeek}
                />
                <TimelineDay
                  key={dateKey}
                  day={day}
                  selected={selectedIndex === index}
                  weather={weather}
                  isCurrentWeek={isCurrentWeek}
                />
              </React.Fragment>
            );
          }
          return (
            <TimelineDay
              key={dateKey}
              day={day}
              selected={selectedIndex === index}
              weather={weather}
              isCurrentWeek={isCurrentWeek}
            />
          );
        })}
      </DaysRow>
    </Component>
  );
};
