import { getItemAndHabitTags } from "./getItemAndHabitTags";

const tagPriority = ["important", "morning", "workday", "evening", "night"];

export const getTaskListTagSummary = (taskList) => {
  const itemIds = taskList.order;
  const tagSummary = {};

  const allSummary = {
    tag: "all",
    count: 0,
    completed: 0,
    punted: 0,
  };

  const untaggedSummary = {
    tag: "none",
    count: 0,
    completed: 0,
    punted: 0,
  };

  itemIds.forEach((itemId) => {
    const item = taskList.itemsMap[itemId];
    const tags = getItemAndHabitTags(item);

    if (item.todo) {
      // update allSummary
      allSummary.count = allSummary.count + 1;

      if (item.punt) {
        allSummary.punted = allSummary.punted + 1;
      } else if (item.value) {
        allSummary.completed = allSummary.completed + 1;
      }

      // update untaggedSummary
      if (tags.length === 0) {
        untaggedSummary.count = untaggedSummary.count + 1;
        if (item.punt) {
          untaggedSummary.punted = untaggedSummary.punted + 1;
        } else if (item.value) {
          untaggedSummary.completed = untaggedSummary.completed + 1;
        }
      }

      //update individual tags
      tags.forEach((tag) => {
        if (!tagSummary[tag]) {
          tagSummary[tag] = { tag, count: 0, completed: 0, punted: 0 };
        }

        if (item.todo) {
          const count = tagSummary[tag].count;
          const completed = tagSummary[tag].completed;
          tagSummary[tag].count = count + 1;

          if (item.punt) {
            tagSummary[tag].punted = tagSummary[tag].punted + 1;
          } else if (item.value) {
            tagSummary[tag].completed = completed + 1;
          }
        }
      });
    }
  });

  const tagData = Object.values(tagSummary).sort((a, b) => {
    const indexOfA = tagPriority.indexOf(a.tag);
    const indexOfB = tagPriority.indexOf(b.tag);

    // make sure that prioritized tags are first.
    if (indexOfA > -1 && indexOfB === -1) {
      return -1;
    }

    if (indexOfB > -1 && indexOfA === -1) {
      return 1;
    }

    if (indexOfA > -1 && indexOfB > -1) {
      return indexOfA - indexOfB;
    }

    const aIsCompleted = a.punted + a.completed === a.count;
    const bIsCompleted = b.punted + b.completed === b.count;

    if (aIsCompleted && !bIsCompleted) {
      return 1;
    }

    if (bIsCompleted && !aIsCompleted) {
      return -1;
    }
    if (bIsCompleted && aIsCompleted) {
      return 0;
    }

    if (a.count > b.count) {
      return -1;
    }

    if (b.count > a.count) {
      return 1;
    }

    return 0;
  });

  tagData.unshift(allSummary);
  if (untaggedSummary.count > 0) {
    tagData.push(untaggedSummary);
  }

  return tagData;
};
