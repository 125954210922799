import { COLORS } from "okthink-core";
import React from "react";
import styled from "styled-components";
import { Text } from "../design/type";

const RADIUS = 28;

const Component = styled.div`
  display: flex;
  width: ${RADIUS * 2}px;
  height: ${RADIUS * 2}px;
  position: relative;
`;

const Labels = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CircleProgressBar = ({
  radius = RADIUS,
  stroke = 4,
  percentage,
}) => {
  const { numCompleted, numTotal } = percentage;
  const percent = numCompleted / numTotal;

  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - percent * circumference;

  let color = COLORS.PINK.value;
  if (percent > 0.8) {
    color = COLORS.GREEN.value;
  } else if (percent > 0.4) {
    color = COLORS.YELLOW.value;
  }

  const label = `${numCompleted}/${numTotal}`;

  return (
    <Component>
      <Labels>
        <Text style={{ fontSize: 10 }}>{label}</Text>
      </Labels>
      <svg height={radius * 2} width={radius * 2}>
        <circle
          stroke={COLORS.BASE3.value}
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </Component>
  );
};
