import React from "react";
import styled from "styled-components";
import { ShowcaseText } from "./type";

const Component = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
`;

const Label = styled(ShowcaseText)`
  width: 100px;
`;

export const Showcase = ({ label, children }) => {
  return (
    <Component>
      <Label>{label}</Label>
      {children}
    </Component>
  );
};
