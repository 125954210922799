import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { keyGroups } from "okthink-core/logic/keyEngine";
import { Keycap } from "../screens/WarPath/Keycap";

const Component = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 300px;
  max-width: 300px;
  color: ${COLORS.WHITE.value};
  font-size: 12px;
  padding: 10px;
  flex-basis: 100px;
  overflow: scroll;
  z-index: 1;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

const KeyStroke = styled.div`
  width: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Description = styled.div``;

const Header = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const SubHeader = styled.div`
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
`;

export const Help = () => {
  return (
    <Component>
      <Header>Keyboard Shortcuts</Header>
      {Object.keys(keyGroups).map((category) => {
        const shortcuts = keyGroups[category];

        return (
          <div>
            <SubHeader>{category}</SubHeader>
            {shortcuts.map((shortcut) => {
              const { description, helpKeys, stroke } = shortcut;
              const keyStrokes = helpKeys ? helpKeys : [stroke];

              return (
                <Item>
                  <KeyStroke>
                    {keyStrokes.map((key, index) => {
                      const reactKey = `${key}${index}`;

                      if (key === "+") {
                        return "+";
                      }
                      return <Keycap key={reactKey} label={key} />;
                    })}
                  </KeyStroke>
                  <Description>{description}</Description>
                </Item>
              );
            })}
          </div>
        );
      })}
    </Component>
  );
};
