import { db } from "./firebase";
import { writeBatch, doc, collection, query, where } from "firebase/firestore";

export const getItemRef = (uid, taskListId, id) => {
  const newItemUrl = `notebooks/${uid}/taskLists/${taskListId}/items/${id}`;
  return doc(db, newItemUrl);
};

export const getItemRefFromPath = (path) => {
  return doc(db, path);
};

export const getNotebookRef = (uid) => {
  const path = `notebooks/${uid}`;
  return doc(db, path);
};

export const getHabitsRef = (uid) => {
  const url = `notebooks/${uid}/habits/`;
  return collection(db, url);
};

export const getHabitsQuery = (uid) => {
  return query(getHabitsRef(uid));
};

export const getTasksListsQuery = (uid) => {
  return query(getTaskListsRef(uid));
};

export const getTaskListsRef = (uid) => {
  const url = `notebooks/${uid}/taskLists/`;
  return collection(db, url);
};

export const getTaskListByDateKeyQuery = (uid, dateKey) => {
  const taskListsRef = getTaskListsRef(uid);
  return query(taskListsRef, where("dateKey", "==", dateKey));
};

export const getTaskListRef = (uid, taskListId) => {
  const url = `notebooks/${uid}/taskLists/${taskListId}`;
  return doc(db, url);
};

export const getItemsCollection = (uid, taskListId) => {
  const url = `notebooks/${uid}/taskLists/${taskListId}/items`;
  return collection(db, url);
};

export const getItemsQuery = (uid, taskListId) => {
  const collection = getItemsCollection(uid, taskListId);
  return query(collection);
};

export const getHabitRef = (uid, habitId) => {
  const habitUrl = `notebooks/${uid}/habits/${habitId}`;
  return doc(db, habitUrl);
};

export const getNewHabitId = (uid) => {
  const habitsRef = getHabitsRef(uid);
  const newDoc = doc(habitsRef);
  return newDoc.id;
};

export const startWriteBatch = () => {
  return writeBatch(db);
};
