import { getTaskListById, moveItemToTaskList } from "../logic/warpathSlice";
import { ensureTaskListAtDateKey } from "./ensureTaskListAtDateKey";
import { dateKeyAdd } from "../logic/dateKeyAdd";

export const moveItemToNextDay = async ({ store, taskListId, itemId }) => {
  let state = store.getState().warpath;

  const taskList = getTaskListById(state, taskListId);
  const { dateKey } = taskList;

  const nextDateKey = dateKeyAdd(dateKey, 1);
  const toTaskListId = await ensureTaskListAtDateKey({
    store,
    dateKey: nextDateKey,
  });

  store.dispatch(
    moveItemToTaskList({
      itemId,
      y: 0,
      fromTaskListId: taskListId,
      toTaskListId,
    })
  );
};
