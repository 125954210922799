import { createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SectionHeader, Text } from "../../design/type";
import { Keycap } from "../WarPath/Keycap";
import { HabitItem } from "./HabitItem";

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  flex-basis: 100px;
  gap: 5px;
`;

const ZeroState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
`;

const selectHabitsData = createSelector(
  [
    (state) => state.notebook.habitsOrder,
    (state) => state.notebook.archivedHabits,
    (state) => state.workspace.cursor,
  ],
  (habitsOrder, archivedHabits, cursor) => {
    const { y, editing } = cursor;
    const activeId = [...habitsOrder, ...archivedHabits][y];
    return { habitsOrder, activeId, archivedHabits, y, editing };
  }
);

export const HabitsList = () => {
  const { habitsOrder, archivedHabits, y, editing, activeId } =
    useSelector(selectHabitsData);

  const itemsRef = useRef({});

  useEffect(() => {
    const itemsMap = itemsRef.current;
    const activeNode = itemsMap[activeId];
    if (activeNode) {
      activeNode.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeId]);

  const handleRef = (habitId, node) => {
    const map = itemsRef.current;
    if (node) {
      map[habitId] = node;
    } else {
      delete map[habitId];
    }
  };

  return (
    <Component>
      {habitsOrder.length === 0 && (
        <ZeroState>
          <Text>Press</Text>
          <Keycap label="o" />
          <Text>to create a new habit</Text>
        </ZeroState>
      )}

      {habitsOrder.map((habitId, index) => {
        const isActive = index === y;
        return (
          <HabitItem
            ref={(node) => {
              handleRef(habitId, node);
            }}
            habitId={habitId}
            isActive={isActive}
            editing={isActive && editing}
          />
        );
      })}

      {archivedHabits.length > 0 && (
        <>
          <SectionHeader>Archived</SectionHeader>
          {archivedHabits.map((habitId, index) => {
            const isActive = habitsOrder.length + index === y;
            return (
              <HabitItem
                ref={(node) => {
                  handleRef(habitId, node);
                }}
                habitId={habitId}
                isActive={isActive}
                editing={isActive && editing}
              />
            );
          })}
        </>
      )}
    </Component>
  );
};
