import { doc } from "firebase/firestore";
import { isTempId, replaceItemId } from "../logic/warpathSlice";
import { getItemsCollection } from "../data/firebase-utils";

export const checkForItemDoc = (store, uid, taskListId, itemId) => {
  if (isTempId(itemId)) {
    // get the current item
    const tempItemId = itemId;
    const itemsCollection = getItemsCollection(uid, taskListId);

    const newDoc = doc(itemsCollection);
    const newId = newDoc.id;
    console.log("checkForItemDoc: newId-> ", newId);
    store.dispatch(replaceItemId({ tempId: tempItemId, newId, taskListId }));
    return newId;
  }
  return itemId;
};
