import { reqDateKeyTaskListId } from "../data/loaders";
import { addNewTaskList, getTaskListIdAtDateKey } from "../logic/warpathSlice";
import { store } from "../logic/store";
import { DATE_COLUMN_INDEX, setColumnId } from "../logic/workspaceSlice";

export const loadTaskListAtDateKey = async (uid, dateKey) => {
  console.log("--loadTaskListAtDateKey--", dateKey);
  const taskListId = await reqDateKeyTaskListId(uid, dateKey);
  if (!taskListId) {
    store.dispatch(addNewTaskList({ dateKey }));
    const dateKeyTaskListId = getTaskListIdAtDateKey(
      store.getState().warpath,
      dateKey
    );

    store.dispatch(
      setColumnId({
        columnIndex: DATE_COLUMN_INDEX,
        columnId: dateKeyTaskListId,
      })
    );
  } else {
    store.dispatch(
      setColumnId({ columnIndex: DATE_COLUMN_INDEX, columnId: taskListId })
    );
  }
};
