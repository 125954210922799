import { configureStore } from "@reduxjs/toolkit";
import { warpathReducer } from "./warpathSlice";
import { workspaceReducer } from "./workspaceSlice";
import { userReducer } from "./userSlice";
import { timelineReducer } from "./timelineSlice";
import { syncMiddleware } from "../operations/sync-middleware";
import { debugTaskListsReducer } from "./debugTaskListsSlice";
import { weatherReducer } from "./weatherSlice";
import { notebookReducer } from "./notebook/notebookSlice";
import { incompletesReducer } from "./incompletes/incompletesSlice";

export const store = configureStore({
  reducer: {
    warpath: warpathReducer,
    incompletes: incompletesReducer,
    workspace: workspaceReducer,
    user: userReducer,
    timeline: timelineReducer,
    debugTaskLists: debugTaskListsReducer,
    weather: weatherReducer,
    notebook: notebookReducer,
  },
  middleware: [syncMiddleware],
});
