import React from "react";
import styled from "styled-components";
import { ShowcaseText } from "../design/type";
import { COLORS } from "okthink-core";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.BASE.value};
  margin: 5px;
  padding: 5px;
`;

export const CheatShowcase = ({ label, children }) => {
  return (
    <Component>
      <ShowcaseText>{label}</ShowcaseText>
      {children}
    </Component>
  );
};
