import React from "react";
import styled from "styled-components";
import { Panel } from "../../components/Panel";
import { StickerText } from "../../components/StickerText";
import { emojis, TagEmoji } from "../../components/TagEmoji";
import { COLORS } from "okthink-core";
import { SectionHeader } from "../../design/type";
import { Tag } from "../WarPath/Tag";
import { HabitsList } from "./HabitsList";

const Component = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${COLORS.WHITE.value};
  font-family: "Work Sans", sans-serif;
  padding: 10px;
  padding-bottom: 0;
  align-items: center;
  justify-content: center;
  margin: 10px;

  border: 1px solid transparent;
  pointer-events: none;
`;

const CenterColumn = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  margin: 20px;
`;

const Notebook = styled.div`
  display: flex;
  flex: 1;
  background-color: rgb(35 20 49);
  border-radius: 8px;
  padding: 40px 20px;
  max-width: 1600px;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  filter: drop-shadow(0px 0px 20px rgba(242, 240, 221, 0.1));
`;

const MainColumn = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  gap: 20px;
  max-width: 1400px;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  padding-top: 40px;
`;

const StickerTitle = styled(StickerText)`
  font-size: 50px;
  transform: rotate(-3.53deg);
  align-self: center;
`;

const EmojiEntry = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px;
  color: white;
  gap: 8px;
`;

export const Settings = () => {
  return (
    <Component>
      <Notebook>
        <MainColumn>
          <Sidebar>
            <StickerTitle label="Settings" />
          </Sidebar>
          <CenterColumn>
            <Panel title="Habits">
              <HabitsList />
            </Panel>
          </CenterColumn>
          <Sidebar>
            <SectionHeader marginTop={1}>Special Tags</SectionHeader>
            {Object.keys(emojis).map((tag) => {
              return (
                <EmojiEntry key={tag}>
                  <TagEmoji tags={[tag]} />
                  <Tag tag={tag} />
                </EmojiEntry>
              );
            })}
          </Sidebar>
        </MainColumn>
      </Notebook>
    </Component>
  );
};
