import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
// Create a root reference
const storage = getStorage();

const getExtension = (path) => {
  return path.split(".").pop();
};

export const uploadImage = async ({ uid, imageFile }) => {
  const extension = getExtension(imageFile.path || imageFile.name);
  const path = `${uid}/images/${uuidv4()}.${extension}`;

  const imageFileRef = ref(storage, path);
  await uploadBytes(imageFileRef, imageFile);
  const downloadUrl = await getDownloadURL(imageFileRef);
  return { path, downloadUrl };
};
