import { keyBinds } from "okthink-core/logic/keyEngine";
import styled from "styled-components";
import { KeyBind } from "../../components/KeyBind";
import { HelpLevel } from "../../components/HelpLevel";

const Component = styled.div`
  background-color: black;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const TaskListHelp = () => {
  const selectedBinds = ["q", "s", "w", "e"];

  const selectedKeyBinds = selectedBinds.map((key) => {
    return keyBinds[key];
  });

  return (
    <HelpLevel level={1}>
      <Component>
        {selectedKeyBinds.map((shortcut) => {
          return <KeyBind shortcut={shortcut} />;
        })}
      </Component>
    </HelpLevel>
  );
};
