import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  ColorName,
  ColorDescription,
  ColorCopied,
  ColorHex,
} from "../design/type";
import { COLORS } from "okthink-core";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin: 7px;
  position: relative;
  overflow: visible;
  cursor: pointer;
  background-color: ${COLORS.BASE.value};
  box-shadow: 0 0 10px #ffffff22;
  padding: 5px;
  width: 90px;
  border-radius: 3px;
  overflow: hidden;
`;

const ColorSquare = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.color};
  height: 60px;
  border-radius: 2px;
  margin-bottom: 4px;
  padding: 4px;
  text-align: center;
  overflow: hidden;
  justify-content: center;
`;

const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  flex: 1;
`;

const InfoBody = styled.div``;

export const Swatch = ({ color, name, description }) => {
  const copyValue = `COLORS.${name}.value`;
  const [showCopied, setShowCopied] = useState(false);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(copyValue);
      console.log("copied:", copyValue);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 5000);
    } catch (err) {
      console.error("Error copying to clipboard");
      console.error(err);
    }
  }, [copyValue, setShowCopied]);

  return (
    <Component onClick={copyToClipboard}>
      <ColorSquare color={color}>
        {showCopied && <ColorCopied>{"Copied to Clipboard!"}</ColorCopied>}
      </ColorSquare>
      <InfoContainer>
        <ColorName>{name}</ColorName>
        <InfoBody>
          <ColorHex>{color}</ColorHex>
          <ColorDescription>{description}</ColorDescription>
        </InfoBody>
      </InfoContainer>
    </Component>
  );
};
