import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { Text, H3 } from "../../design/type";
import { getAllTaskLists } from "okthink-core/data/loaders";
import { deleteTaskList } from "okthink-core/data/firebase-fixes";
import { setTaskLists, taskListDeleted } from "okthink-core/logic/debugTaskListsSlice";
import { Button } from "../../components/Button";

const Component = styled.div`
  flex: 1;
  padding: 5px;
`;

const ItemComponent = styled.div`
  background-color: ${COLORS.BASE.value};
  border-left: 2px solid ${COLORS.TEAL.value};
  padding: 4px;
  margin: 7px 0px;
`;

const Field = styled.div`
  margin: 10px 0px;
`;
const Key = styled(Text)`
  color: ${COLORS.PURPLE.value};
`;
const Value = styled(Text)``;

const TaskListData = ({ taskList, hitDelete }) => {
  const { meta, ...rest } = taskList;

  return (
    <ItemComponent>
      <Text color={COLORS.PURPLE.value}>{meta.id}</Text>
      {Object.keys(rest).map((key) => {
        const value = rest[key];
        const valueLabel = Array.isArray(value) ? value.join(", ") : value;

        return (
          <Field>
            <Key>{key}</Key>
            <Value>{valueLabel}</Value>
          </Field>
        );
      })}

      <Button
        label="del"
        onClick={() => hitDelete(meta.id)}
        appearance="small"
      />
    </ItemComponent>
  );
};

export const DebugTaskList = () => {
  const taskLists = useSelector((state) => state.debugTaskLists.taskLists);
  const dispatch = useDispatch();
  const uid = useSelector((state) => (state.user ? state.user.uid : null));

  const hitDeleteTaskList = async (taskListId) => {
    await deleteTaskList(uid, taskListId);
    dispatch(taskListDeleted({ taskListId }));
  };

  useEffect(() => {
    const loader = async () => {
      if (!uid) {
        return;
      }
      const debugTaskLists = await getAllTaskLists(uid);

      dispatch(setTaskLists({ taskLists: debugTaskLists }));
    };
    loader();
  }, [uid, dispatch]);

  return (
    <Component>
      <H3>TaskLists</H3>
      {taskLists.map((taskList) => {
        return (
          <TaskListData taskList={taskList} hitDelete={hitDeleteTaskList} />
        );
      })}
    </Component>
  );
};
