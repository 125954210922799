import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { setEditorDraft } from "okthink-core/logic/workspaceSlice";

const Component = styled.div`
  font-size: 12px;
  display: flex;
  flex: 1;
  background-color: ${COLORS.BASE.value};
  // border: 1px solid ${COLORS.WHITE.value};
  padding: 4px;
`;

const TextInput = styled.textarea`
  width: 99%;
  background-color: transparent;
  color: ${COLORS.WHITE.value};
  border: none;
  &:focus {
    outline: none;
  }
  font-family: Work Sans;
  &::-webkit-scrollbar {
    width: 0px;
  }
  resize: none;
  font-size: 12px;
  font-family: Roboto Mono;
`;

export const Editor = () => {
  const dispatch = useDispatch();

  const editorDraft = useSelector((state) => {
    return state.workspace.editor.draft;
  });

  const onChange = (e) => {
    dispatch(setEditorDraft({ value: e.target.value }));
  };

  const onKeyUp = (e) => {
    const el = e.target;
    el.style.height = "auto";
    const newHeight = el.scrollHeight;
    el.style.height = `${newHeight}px`;
  };

  return (
    <Component>
      <TextInput
        value={editorDraft}
        onChange={onChange}
        autoFocus
        onKeyUp={onKeyUp}
      />
    </Component>
  );
};
