import React from "react";
import styled from "styled-components";
import { Text } from "../design/type";
import { COLORS } from "okthink-core";

const Component = styled.div`
  display: flex;
  background-color: ${COLORS.BASE3.value};
  padding: 2px;
  gap: 1px;
  border-radius: 1px;
`;

const DataDisplay = styled.div`
  background-color: ${COLORS.BASE.value};
  font-size: 12px;
  white-space: pre-wrap;
  font-family: monospace;
  padding: 4px;
  color: ${COLORS.WHITE.value};
  flex: 1;
`;

const Label = styled(Text)`
  min-width: 85px;
  font-family: monospace;
`;

const KeyValueGroup = styled.span`
  display: inline-block;
  background-color: ${COLORS.BASE3.value};
  margin: 2px;
  padding: 2px;
  border-radius: 2px;
`;

const Value = styled(Text)`
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: monospace;
`;

const Key = styled(Text)`
  display: inline-block;
  color: ${COLORS.YELLOW.value};
  margin: 2px;
  padding: 2px;
  font-family: monospace;
`;

const renderArray = (arr) => {
  return (
    <DataDisplay>
      <Text>{arr.length} items</Text>
      {arr.map((item, index) => {
        return (
          <KeyValueGroup key={index}>
            <Key>{index}</Key>
            <Value>{item}</Value>
          </KeyValueGroup>
        );
      })}
    </DataDisplay>
  );
};

const renderObject = (obj) => {
  const keys = Object.keys(obj);
  const size = keys.length;

  return (
    <DataDisplay>
      <Text>{size} items</Text>
      {keys.map((key) => {
        const value = obj[key];
        const stringified = JSON.stringify(value, null, 2);
        return (
          <KeyValueGroup key={key}>
            <Key>{key}:</Key>
            <Value>{stringified}</Value>
          </KeyValueGroup>
        );
      })}
    </DataDisplay>
  );
};

export const KeyValue = ({ label, value }) => {
  const isArray = Array.isArray(value);
  const renderFn = isArray ? renderArray : renderObject;

  return (
    <Component>
      <Label>{label}</Label>
      {renderFn(value)}
    </Component>
  );
};
