//weather
import dayjs from "dayjs";

const apiKey = "11debb1c3b0227f5b7762ec3cda032de";

const getGeo = async (city, state, country = "US", limit = 1) => {
  const url = `https://api.openweathermap.org/geo/1.0/direct?q=${city},${state},${country}&limit=${limit}&appid=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data[0];
  } catch (e) {
    console.log("error getting geo");
    throw e;
  }
};

export const getWeather = async (city, state, country = "US") => {
  const { lat, lon } = await getGeo(city, state, country);
  const count = 8;

  const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&cnt=${count}&units=imperial&appid=${apiKey}`;
  try {
    const response = await fetch(url);
    const weatherData = await response.json();
    return processWeather(weatherData);
  } catch (e) {
    console.log("error getting weather");
    throw e;
  }
};

const getDateKey = (dt) => {
  const date = dayjs(dt * 1000);
  return date.format("YYYY-MM-DD");
};

const processWeather = (weatherData) => {
  const weather = {};
  const { current, daily } = weatherData;

  const dateKey = getDateKey(current.dt);
  const nowWeather = {
    dateKey,
    temp: current.temp,
    feelsLike: current.feels_like,
  };

  daily.forEach((weatherDay) => {
    const { dt, temp } = weatherDay;
    const dateKey = getDateKey(dt);
    const weatherObj = {
      dateKey,
      min: temp.min,
      max: temp.max,
    };
    weather[dateKey] = weatherObj;
  });
  // make sure to replace today
  weather[dateKey] = {
    ...weather[dateKey],
    ...nowWeather,
  };
  return weather;
};
