import React from "react";
import styled from "styled-components";

import { keyGroups } from "okthink-core/logic/keyEngine";
import { Keycap } from "./Keycap";
import { COLORS } from "okthink-core";
import { useSelector } from "react-redux";

const Component = styled.div`
  background-color: black;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const KeyStroke = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.2);
  gap: 4px;
`;

const Description = styled.div`
  color: ${COLORS.WHITE.value};
  font-size: 10px;
`;

export const TimelineShortcuts = () => {
  const shortcuts = keyGroups.Timeline;

  const helpLevel = useSelector((state) => {
    const { workspace } = state;
    return workspace.viewOptions.helpLevel;
  });

  if (helpLevel === 0) {
    return null;
  }

  return (
    <Component>
      {shortcuts.map((shortcut) => {
        const { description, helpKeys, stroke } = shortcut;
        const keyStrokes = helpKeys ? helpKeys : [stroke];

        return (
          <Item>
            <KeyStroke>
              {keyStrokes.map((key, index) => {
                const reactKey = `${key}${index}`;

                if (key === "+") {
                  return "+";
                }
                return <Keycap key={reactKey} label={key} />;
              })}
            </KeyStroke>
            <Description>{description}</Description>
          </Item>
        );
      })}
    </Component>
  );
};
