import React from "react";
import styled from "styled-components";
import { Swatch } from "../design/Swatch";
import { COLORS } from "okthink-core";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: visible;
  z-index: 10;
  margin-bottom: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
`;

export const ColorCheat = () => {
  return (
    <Component>
      <Column>
        {Object.values(COLORS).map((color) => {
          return (
            <Swatch
              color={color.value}
              description={color.description}
              name={color.name}
            />
          );
        })}
      </Column>
    </Component>
  );
};
