import { SoundEffects } from "../logic/soundEffects";
import {
  insertAfter,
  toggleItem,
  getVisibleOrder,
  toggleItemType,
  getItem,
  getItemIndex,
  sortTaskList,
  moveItemToTaskList,
  itemImageUploadStarting,
  itemImageUploadCompleted,
  itemImageUploadFailed,
  deleteItemAttachment,
  togglePuntItem,
  commit,
  moveItemBefore,
  moveItemAfter,
  safeDeleteByItemId,
} from "../logic/warpathSlice";
import { uploadImage } from "../data/files";
import { moveItemToNextDay } from "../operations/moveItemToNextDay";

import {
  cursorUp,
  cursorDown,
  cursorDownAndEditing,
  clearViewOptionsAndSetY,
  getColumnTagIndex,
  setEditorDraft,
} from "../logic/workspaceSlice";
import {
  getBacklogTaskListId,
  setHabitCompletion,
} from "../logic/notebook/notebookSlice";
import { getTaskListTagSummary } from "./getTaskListTagSummary";

const getUnfilteredY = (store, taskListId, y) => {
  const { taskLists } = store.getState().warpath;
  const { hideComplete } = store.getState().workspace.viewOptions;
  const taskList = taskLists[taskListId];

  const tag = getSelectedTag(store, taskListId);

  const visibleOrder = getVisibleOrder(
    store.getState().warpath,
    taskListId,
    hideComplete,
    tag
  );

  const itemId = visibleOrder[y];
  const unfilteredY = taskList.order.indexOf(itemId);
  return unfilteredY;
};

const getSelectedTag = (store, taskListId) => {
  const { taskLists } = store.getState().warpath;
  const taskList = taskLists[taskListId];
  const summary = getTaskListTagSummary(taskList);

  const tagIndex = getColumnTagIndex(
    store.getState().workspace,
    taskList.meta.id
  );

  const { tag } = summary[tagIndex];
  return tag;
};

export const WarpathDelegate = (store) => {
  return {
    insertAfter: ({ columnId: taskListId, itemId, text }) => {
      store.dispatch(insertAfter({ taskListId, itemId, text }));

      const { taskLists } = store.getState().warpath;
      const taskList = taskLists[taskListId];
      // This is a snafu related to tasksLists faking that they're size 1 when they're empty. Now I have to send a maxY
      const maxY = taskList.order.length - 1;

      store.dispatch(setEditorDraft({ value: text }));
      store.dispatch(cursorDownAndEditing({ maxY }));
    },
    moveItemUp: ({ columnId: taskListId, itemId }) => {
      const tag = getSelectedTag(store, taskListId);
      const { hideComplete } = store.getState().workspace.viewOptions;

      const visibleOrder = getVisibleOrder(
        store.getState().warpath,
        taskListId,
        hideComplete,
        tag
      );

      const itemIndex = visibleOrder.indexOf(itemId);
      if (itemIndex > 0) {
        const beforeItemId = visibleOrder[itemIndex - 1];
        store.dispatch(moveItemBefore({ taskListId, itemId, beforeItemId }));
        store.dispatch(cursorUp());
      }
    },
    moveItemDown: ({ columnId: taskListId, itemId }) => {
      const tag = getSelectedTag(store, taskListId);
      const { hideComplete } = store.getState().workspace.viewOptions;

      const visibleOrder = getVisibleOrder(
        store.getState().warpath,
        taskListId,
        hideComplete,
        tag
      );

      const itemIndex = visibleOrder.indexOf(itemId);
      if (itemIndex < visibleOrder.length - 1) {
        const afterItemId = visibleOrder[itemIndex + 1];
        store.dispatch(cursorDown({ maxY: Infinity }));
        store.dispatch(moveItemAfter({ taskListId, itemId, afterItemId }));
      }
    },
    moveItem: ({ itemId, y, fromColumnId, toX, toColumnId }) => {
      const unfilteredY = getUnfilteredY(store, fromColumnId, y);
      store.dispatch(clearViewOptionsAndSetY({ y: unfilteredY }));

      store.dispatch(
        moveItemToTaskList({
          itemId,
          y,
          fromTaskListId: fromColumnId,
          toTaskListId: toColumnId,
        })
      );

      const { columns } = store.getState().workspace;
      const newColumnId = columns[toX].columnId;

      const newY = getItemIndex(store.getState().warpath, newColumnId, itemId);
      return { newY };
    },
    moveItemToTomorrow: (taskListId, itemId) => {
      moveItemToNextDay({ store, taskListId, itemId });
    },
    moveItemToBacklog: (taskListId, itemId) => {
      const toTaskListId = getBacklogTaskListId(store.getState().notebook);
      store.dispatch(
        moveItemToTaskList({
          itemId,
          y: 0, // this will put the item at the top of the backlog
          fromTaskListId: taskListId,
          toTaskListId,
        })
      ); // moveItemToTaskList should make the taskLists dirty.
    },
    toggleItem: ({ columnId: taskListId, itemId, item, dateKey }) => {
      SoundEffects.done();
      const { habitId } = item;

      store.dispatch(toggleItem({ taskListId, itemId }));

      // check if the item has a habit, and then toggle call to toggle the habit
      // for that date as well.
      if (habitId) {
        const nextValue = !item.value;
        store.dispatch(
          setHabitCompletion({
            habitId,
            dateKey,
            nextValue,
            modified: new Date(),
          })
        );
      }
    },
    toggleItemType: (taskListId, itemId) => {
      store.dispatch(toggleItemType({ taskListId, itemId }));
    },
    deleteItem: ({ columnId: taskListId, itemId, item }) => {
      if (item.habitId) {
        store.dispatch(togglePuntItem({ taskListId, itemId }));
      } else {
        store.dispatch(safeDeleteByItemId({ taskListId, itemId, hard: true }));
      }
    },
    deleteItemAttachment: (taskListId, itemId) => {
      store.dispatch(deleteItemAttachment({ taskListId, itemId }));
    },
    getColumnSize: (taskListId) => {
      const { taskLists } = store.getState().warpath;
      const { hideComplete } = store.getState().workspace.viewOptions;
      const taskList = taskLists[taskListId];
      if (!taskList || taskList.order.length === 0) {
        return 1; // not 0, we still have the placeholder item
      }

      const tag = getSelectedTag(store, taskListId);
      const visibleOrder = getVisibleOrder(
        store.getState().warpath,
        taskListId,
        hideComplete,
        tag
      );

      return visibleOrder.length;
    },
    getItemAt: (taskListId, y, viewOptions) => {
      const warpathState = store.getState().warpath;
      const { hideComplete } = viewOptions;

      const taskList = warpathState.taskLists[taskListId];
      if (!taskList) {
        return { itemId: null, item: null };
      }

      const tag = getSelectedTag(store, taskListId);

      const visibleOrder = getVisibleOrder(
        warpathState,
        taskListId,
        hideComplete,
        tag
      );

      const itemId = visibleOrder[y];
      const item = getItem(warpathState, taskListId, itemId);
      return { itemId, item };
    },
    getColumn: (taskListId) => {
      const { taskLists } = store.getState().warpath;
      const taskList = taskLists[taskListId];
      return taskList;
    },
    sort: (taskListId) => {
      store.dispatch(sortTaskList({ taskListId }));
    },
    handlePaste: (taskListId, itemId, uid, blob) => {
      const sideEffect = async () => {
        store.dispatch(itemImageUploadStarting({ taskListId, itemId, blob }));
        try {
          const { path } = await uploadImage({ uid, imageFile: blob });
          store.dispatch(
            itemImageUploadCompleted({ taskListId, itemId, path })
          );
        } catch (e) {
          console.log("error uploadingImage");
          console.log(e);
          itemImageUploadFailed({ taskListId, itemId });
        }
      };
      sideEffect();
    },
    puntItem: (taskListId, itemId) => {
      store.dispatch(togglePuntItem({ taskListId, itemId }));
    },
    getTextValue: (item) => {
      let text = item.text ? item.text.trim() : "";
      return text;
    },
    getTagSummary: (taskListId) => {
      const { taskLists } = store.getState().warpath;
      const taskList = taskLists[taskListId];
      return getTaskListTagSummary(taskList);
    },
    getNewItemText: (taskListId) => {
      const { warpath, workspace } = store.getState();

      const { taskLists } = warpath;
      const taskList = taskLists[taskListId];
      const tagSummary = getTaskListTagSummary(taskList);
      const tagIndex = getColumnTagIndex(workspace, taskListId);

      const { tag } = tagSummary[tagIndex];

      let text = null;
      if (tag !== "all" && tag !== "none") {
        text = `#${tag}`;
      }
      return text;
    },
    commit: ({ itemId, columnId, dateKey, newValue, currentValue, item }) => {
      // if there's no newValue, then clear this item
      if (!item.habitId && (!newValue || newValue.trim() === "")) {
        // then itemId should be removed.
        store.dispatch(safeDeleteByItemId({ itemId, taskListId: columnId }));
        return;
      } else if (currentValue === newValue.trim()) {
        // don't commit if there isn't a change
        return;
      }

      const commitMeta = {
        taskListId: columnId,
        dateKey,
      };

      store.dispatch(
        commit({
          id: itemId,
          newValue,
          commitMeta,
        })
      );
    },
  };
};
