import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Text } from "../../design/type";
import { COLORS } from "okthink-core";
import { themeVal } from "../../design/themeVal";
import { getDateKey } from "okthink-core/logic/dateUtils";
import dayjs from "dayjs";
import { JapanTrip } from "../../components/JapanTrip";
import { useSelector } from "react-redux";

export const DEFAULT = "DEFAULT";
export const WEEKEND = "WEEKEND";
export const ACTIVE_WEEK = "ACTIVE_WEEK";
export const TODAY = "TODAY";
export const SELECTED = "SELECTED";

const THEMES = {
  DEFAULT: {
    labelColor: COLORS.TIMELINE_DAY.value,
  },
  ACTIVE_WEEK: {
    labelColor: COLORS.TIMELINE_CURRENT_WEEK.value,
  },
  WEEKEND: {
    labelColor: COLORS.TIMELINE_WEEKEND.value,
  },
  TODAY: {
    labelColor: COLORS.WHITE.value,
  },
  SELECTED: {
    labelColor: COLORS.TIMELINE_ACTIVE.value,
    backgroundColor: COLORS.ACTIVE_DAY_BACKGROUND.value,
  },
};

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${themeVal("backgroundColor", "default")};
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s;
  overflow: visible;
  position: relative;
  max-width: 20px;
`;

const DayLabel = styled(Text)`
  color: ${themeVal("labelColor")};
  font-family: Lustria, serif;
  font-size: ${themeVal("fontSize", "10px")};
  transition: all 0.5s;
  line-height: 1em;
  opacity: 0.5;
  text-transform: uppercase;
`;

const NumberLabel = styled(Text)`
  color: ${themeVal("labelColor")};
  font-family: Lustria, serif;
  font-size: ${themeVal("fontSize", "14px")};
  transition: all 0.5s;
`;

const getDayType = (day, selected, isCurrentWeek) => {
  if (selected) {
    return SELECTED;
  }
  if (day.isToday()) {
    return TODAY;
  }

  if (isCurrentWeek) {
    return ACTIVE_WEEK;
  }

  const dayNum = day.day();
  if (dayNum === 0 || dayNum === 6) {
    return WEEKEND;
  }

  return DEFAULT;
};

// <Forecast weather={weather} />

const Emoji = styled.div`
  margin-top: 2px;
  font-size: 16px;
`;

export const TimelineDay = ({
  day,
  selected = false,
  isCurrentWeek = false,
  weather,
}) => {
  const dayNumber = day.format("D");
  const type = getDayType(day, selected, isCurrentWeek);
  const dayLabel = day.format("ddd");
  const dateKey = getDateKey(day);

  const specialDate = useSelector((state) => {
    return state.notebook.specialDates[dateKey];
  });

  const japanTrip = specialDate && specialDate === "japanTrip";
  const emoji = specialDate && specialDate !== "japanTrip";

  return (
    <ThemeProvider theme={THEMES[type]}>
      <Component selected={selected}>
        <NumberLabel>{dayNumber}</NumberLabel>
        <DayLabel>{dayLabel}</DayLabel>
        {japanTrip && <JapanTrip daysUntil={day.diff(dayjs(), "day")} />}
        {emoji && <Emoji>{specialDate}</Emoji>}
      </Component>
    </ThemeProvider>
  );
};
