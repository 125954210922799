import { getNotebookRef } from "../data/firebase-utils";
import { setDoc } from "firebase/firestore";
import { resetDirtyTags } from "../logic/notebook/notebookSlice";

export const checkForTagsSync = async (uid, store) => {
  console.log("--checkForTagsSync--");
  const { dirtyTags, tags } = store.getState().notebook;
  if (dirtyTags) {
    try {
      console.log("there were dirty tags");
      const notebookRef = getNotebookRef(uid);
      await setDoc(
        notebookRef,
        {
          tags,
        },
        { merge: true }
      );
      store.dispatch(resetDirtyTags());
    } catch (e) {
      console.error("there was an error saving the tags");
    }
  }
};
