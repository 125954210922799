import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { COLORS } from "okthink-core";
import { H3 } from "../design/type";

const Component = styled.div`
  background-color: ${COLORS.BASE.value};
  font-size: 12px;
  white-space: pre-wrap;
  font-family: monospace;
  color: ${COLORS.WHITE.value};
  padding: 10px;
  flex: 1;
`;

const getJSONText = (obj) => {
  return JSON.stringify(obj, null, 2);
};

export const ReduxDisplay = ({ label }) => {
  const obj = useSelector((state) => state[label]);
  return (
    <Component>
      <H3>{label}</H3>
      {getJSONText(obj)}
    </Component>
  );
};
