import { createSlice } from "@reduxjs/toolkit";
import { getTags } from "./getTags";
import { dateKeyAdd } from "./dateKeyAdd";
import { makeItem } from "./warpath/makeItem";
import { getItemAndHabitTags } from "./getItemAndHabitTags";

const NOTE_ITEM = "NOTE_ITEM";
const TODO_ITEM = "TODO_ITEM";

export const makeTempId = () => {
  return `temp${-Math.random() * 9999999}`;
};

export const isTempId = (id) => {
  return id.includes("temp");
};

const initialState = {
  history: [],
  taskLists: {},
  dateKeys: {},
  activeItemType: TODO_ITEM,
  habitIds: [],
};

const makeHabitItems = (habits = []) => {
  const itemsMap = {};
  const order = [];
  habits.forEach((habitId) => {
    const newItemId = makeTempId();
    const newItem = makeItem(newItemId, null, false, true);
    newItem.habitId = habitId;
    itemsMap[newItemId] = newItem;
    order.push(newItemId);
  });
  return { order, itemsMap };
};

/*
 * ok, so how do we integrate firebase?
 * The left column needs to come from a query of Todos for today.
 * It's ordered, and the concept of "today" is virtual. So how do we maintain order of things?
 * Maybe the concept of "Today" is actually a data model. Today is a document with an ordered list of todo ids. That way the todo doesn't need to keep track of its order.
 * "On my mind" is actually kind of what a "today" view looks like.
 *
 * The Today firebase document for a given date is dynamically created the moment you do something for a particular date, like add a note or
 */

export const getItemAt = (state, taskListId, y) => {
  const taskList = state.taskLists[taskListId];
  const itemId = taskList.order[y];
  return taskList.itemsMap[itemId];
};

export const getItem = (state, taskListId, itemId) => {
  const taskList = state.taskLists[taskListId];
  return taskList.itemsMap[itemId];
};

export const getItemIndex = (state, taskListId, itemId) => {
  const taskList = state.taskLists[taskListId];
  return taskList.order.indexOf(itemId);
};

export const getTaskListByDateKey = (state, dateKey) => {
  return state.dateKeys[dateKey];
};

export const getTaskListById = (state, taskListId) => {
  return state.taskLists[taskListId];
};

export const getVisibleOrder = (
  state,
  taskListId,
  hideComplete,
  tag = null
) => {
  const taskList = state.taskLists[taskListId];

  let order = taskList.order;

  // this also means hide punted.
  if (hideComplete) {
    order = taskList.order.filter((itemId) => {
      const item = taskList.itemsMap[itemId];
      return !item.todo || (!item.value && !item.punt);
    });
  }

  if (tag && tag !== "all" && tag !== "none") {
    order = order.filter((itemId) => {
      const item = taskList.itemsMap[itemId];
      const tags = getItemAndHabitTags(item);
      return tags.includes(tag);
    });
  } else if (tag === "none") {
    order = order.filter((itemId) => {
      const item = taskList.itemsMap[itemId];
      const tags = getItemAndHabitTags(item);
      return tags.length === 0;
    });
  }

  return order;
};

const addNewTaskListAtDate = (
  state,
  dateKey,
  taskListId,
  withDefaultItems = true
) => {
  state.taskLists[taskListId] = {
    meta: {
      id: taskListId,
      dirty: false,
    },
    itemsMap: {},
    dateKey,
    order: [],
  };

  if (withDefaultItems) {
    const { order, itemsMap } = makeHabitItems(state.habitIds);
    state.taskLists[taskListId].itemsMap = itemsMap;
    state.taskLists[taskListId].order = order;
  }

  state.dateKeys[dateKey] = taskListId;
};

const getTaskListAtDateKey = (state, dateKey) => {
  let taskListId = state.dateKeys[dateKey];
  if (!taskListId) {
    addNewTaskListAtDate(state, dateKey);
  }

  taskListId = state.dateKeys[dateKey];
  return state.taskLists[taskListId];
};

const removeItem = (taskList, item) => {
  const { id } = item.meta;
  const index = taskList.order.indexOf(id);
  taskList.order.splice(index, 1);
  delete taskList.itemsMap[id];
  taskList.meta.dirty = true;
};

const prependItem = (taskList, item) => {
  const { id } = item.meta;
  taskList.itemsMap[id] = item;
  taskList.order.unshift(id);
  taskList.meta.dirty = true;
};

export const getTaskListIdAtDateKey = (state, dateKey) => {
  const taskListId = state.dateKeys[dateKey];
  return taskListId;
};

export const warpathSlice = createSlice({
  name: "warpath",
  initialState,
  reducers: {
    setHabitIds: (state, action) => {
      const { habitIds } = action.payload;
      state.habitIds = habitIds;
    },
    setTaskList: (state, action) => {
      const { meta, itemsMap, dateKey, order = [] } = action.payload;
      const { id } = meta;
      state.taskLists[id] = {
        meta,
        itemsMap,
        dateKey,
        order,
      };
      state.dateKeys[dateKey] = id;
    },
    addTaskLists: (state, action) => {
      const { taskLists } = action.payload;
      taskLists.forEach((taskList) => {
        const { meta, itemsMap, dateKey, order = [] } = taskList;
        const { id } = meta;
        state.taskLists[id] = {
          meta,
          itemsMap,
          dateKey,
          order,
        };
        state.dateKeys[dateKey] = id;
      });
    },
    addNewTaskList: (state, action) => {
      const {
        dateKey,
        taskListId = makeTempId(),
        withDefaultItems = true,
      } = action.payload;

      addNewTaskListAtDate(state, dateKey, taskListId, withDefaultItems);
    },
    replaceTaskListId: (state, action) => {
      const { tempTaskListId, newTaskListId } = action.payload;
      const taskList = state.taskLists[tempTaskListId];
      taskList.meta.id = newTaskListId;
      state.taskLists[newTaskListId] = taskList;
      delete state.taskLists[tempTaskListId];
      const dateKey = taskList.dateKey;
      if (dateKey) {
        state.dateKeys[dateKey] = newTaskListId;
      }
    },
    replaceItemId: (state, action) => {
      const { tempId, newId, taskListId } = action.payload;
      const activeTaskList = state.taskLists[taskListId];
      const index = activeTaskList.order.indexOf(tempId);

      activeTaskList.order.splice(index, 1, newId);
      const item = activeTaskList.itemsMap[tempId];
      activeTaskList.itemsMap[newId] = item;
      delete activeTaskList.itemsMap[tempId];
      item.meta.id = newId;
    },
    toggleItem: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.value = !item.value;
      item.meta.dirty = true;
      const taskList = state.taskLists[taskListId];
      taskList.meta.dirty = true;
    },
    toggleItemType: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.todo = !item.todo;
      state.activeItemType = item.todo ? TODO_ITEM : NOTE_ITEM;

      item.meta.dirty = true;
      const taskList = state.taskLists[taskListId];
      taskList.meta.dirty = true;
    },

    insertAfter: (state, action) => {
      const { taskListId, itemId, text = "" } = action.payload;
      const taskList = state.taskLists[taskListId];
      const y = taskList.order.indexOf(itemId);

      const newId = makeTempId();

      const isTodo = state.activeItemType === TODO_ITEM;
      const newItem = makeItem(newId, text, false, isTodo);
      const newY = y + 1;

      taskList.order.splice(newY, 0, newId);
      taskList.itemsMap[newId] = newItem;
    },
    pushItemToTaskList: (state, action) => {
      const { taskListId, item } = action.payload;
      const taskList = state.taskLists[taskListId];
      if (!taskList) {
        return;
      }
      const { id: itemId } = item.meta;
      taskList.order.push(itemId);
      taskList.itemsMap[itemId] = item;
    },
    pushItemToTaskListAtDate: (state, action) => {
      const { dateKey, item } = action.payload;
      const taskList = getTaskListAtDateKey(state, dateKey);
      if (!taskList) {
        return;
      }
      item.meta.dirty = false;
      const { id: itemId } = item.meta;
      taskList.order.push(itemId);
      taskList.itemsMap[itemId] = item;
    },
    moveItemBefore: (state, action) => {
      const { taskListId, itemId, beforeItemId } = action.payload;
      const taskList = state.taskLists[taskListId];
      const { order } = taskList;
      const beforeIndex = order.indexOf(beforeItemId);
      if (beforeIndex >= 0) {
        const itemIndex = order.indexOf(itemId);
        order.splice(itemIndex, 1); // remove the item from the liast
        order.splice(beforeIndex, 0, itemId);
      }
      taskList.meta.dirty = true;
    },
    moveItemAfter: (state, action) => {
      const { taskListId, itemId, afterItemId } = action.payload;
      const taskList = state.taskLists[taskListId];
      const { order } = taskList;
      const afterIndex = order.indexOf(afterItemId);
      const itemIndex = order.indexOf(itemId);
      order.splice(afterIndex + 1, 0, itemId); // first add the item
      order.splice(itemIndex, 1); // then remove it.
      taskList.meta.dirty = true;
    },

    moveItemToTaskList: (state, action) => {
      const { itemId, y, fromTaskListId, toTaskListId } = action.payload;
      const fromTaskList = state.taskLists[fromTaskListId];
      const toTaskList = state.taskLists[toTaskListId];

      //add to taskList
      const safeY = Math.min(toTaskList.order.length - 1, y);

      toTaskList.order.splice(safeY, 0, itemId);
      const item = fromTaskList.itemsMap[itemId];
      toTaskList.itemsMap[itemId] = item;

      //subtract from taskList
      fromTaskList.order = fromTaskList.order.filter((id) => id !== itemId);
      delete fromTaskList.itemsMap[itemId];

      fromTaskList.meta.dirty = true;
      toTaskList.meta.dirty = true;
      toTaskList.itemsMap[itemId].meta.dirty = true;
    },
    moveItemToTomorrow: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const taskList = getTaskListById(state, taskListId);
      const item = getItem(state, taskListId, itemId);
      const { dateKey } = taskList;

      const tomorrowDateKey = dateKeyAdd(dateKey, 1);
      const nextTaskList = getTaskListAtDateKey(state, tomorrowDateKey);

      removeItem(taskList, item);
      prependItem(nextTaskList, item);
    },
    commit: (state, action) => {
      const { id, newValue, commitMeta } = action.payload;
      const { taskListId } = commitMeta;
      const taskList = state.taskLists[taskListId];
      const item = taskList.itemsMap[id];
      item.text = newValue;
      item.tags = getTags(newValue);
      item.meta.dirty = true;
      taskList.meta.dirty = true;
    },
    safeDeleteByItemId: (state, action) => {
      const { taskListId, itemId, hard = false } = action.payload;
      const item = getItem(state, taskListId, itemId);
      const taskList = getTaskListById(state, taskListId);

      // don't delete if it has images
      if (!hard && item.images && item.images.length > 0) {
        return;
      }

      const { order } = taskList;
      const newOrder = order.filter((id) => id !== itemId);
      taskList.order = newOrder;
      delete taskList.itemsMap[itemId];
      taskList.meta.dirty = true;
    },
    sortTaskList: (state, action) => {
      const { taskListId } = action.payload;
      const taskList = state.taskLists[taskListId];
      const newOrder = taskList.order.sort((itemIdA, itemIdB) => {
        const a = taskList.itemsMap[itemIdA];
        const b = taskList.itemsMap[itemIdB];
        if (a.punt === b.punt) {
          if (a.value === b.value) {
            return 0;
          }
          return a.value ? -1 : 1;
        }
        return a.punt ? -1 : 1;
      });

      taskList.order = newOrder;
    },
    itemImageUploadStarting: (state, action) => {
      const { taskListId, itemId, blob } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.meta.uploadingImage = true;
      item.meta.uploadPreviewFile = blob;
    },
    itemImageUploadCompleted: (state, action) => {
      const { taskListId, itemId, path } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.images = [path];
      item.meta.uploadingImage = false;
      delete item.meta.uploadPreviewFile;

      item.meta.dirty = true;
      const taskList = state.taskLists[taskListId];
      taskList.meta.dirty = true;
    },
    itemImageUploadFailed: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.meta.uploadingImage = false;
      delete item.meta.uploadPreviewFile;
    },
    deleteItemAttachment: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.images = [];

      item.meta.dirty = true;
      const taskList = state.taskLists[taskListId];
      taskList.meta.dirty = true;
    },
    togglePuntItem: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.punt = !item.punt;
      item.meta.dirty = true;
      const taskList = state.taskLists[taskListId];
      taskList.meta.dirty = true;
    },
    taskListSynced: (state, action) => {
      const { taskListId } = action.payload;
      const taskList = getTaskListById(state, taskListId);
      taskList.meta.dirty = false;
      Object.values(taskList.itemsMap).forEach((item) => {
        item.meta.dirty = false;
      });
    },
    setItemDirty: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const taskList = getTaskListById(state, taskListId);
      taskList.meta.dirty = true;
      const item = getItem(state, taskListId, itemId);
      item.meta.dirty = true;
    },
    itemSynced: (state, action) => {
      const { taskListId, itemId } = action.payload;
      const item = getItem(state, taskListId, itemId);
      item.meta.dirty = false;
    },
  },
});

export const {
  setHabitIds,
  setDay,
  setEditing,
  toggleEditing,
  addNewTaskList,
  insertAfter,
  safeDeleteByItemId,
  commit,
  replaceTaskListId,
  replaceItemId,
  moveItemToTaskList,
  setTaskList,
  toggleItem,
  toggleItemType,
  sortTaskList,
  itemImageUploadStarting,
  itemImageUploadCompleted,
  itemImageUploadFailed,
  deleteItemAttachment,
  taskListSynced,
  moveItemToTomorrow,
  moveItemToToday,
  itemSynced,
  togglePuntItem,
  pushItemToTaskList,
  pushItemToTaskListAtDate,
  addTaskLists,
  setItemDirty,
  moveItemBefore,
  moveItemAfter,
} = warpathSlice.actions;

export const warpathReducer = warpathSlice.reducer;
