export const splitTagsAndUrls = (inputString) => {
  // Regular expression for matching tags and URLs
  const tagAndUrlRegex = /(#\w+)|(https?:\/\/[^\s]+)/gi;

  const items = inputString
    .split(tagAndUrlRegex)
    .filter((item) => item !== "" && item !== undefined);

  return items;
};
