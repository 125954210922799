import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";

const storage = getStorage();

export const useImageUrl = (path) => {
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    if (!path) {
      setDownloadUrl(null);
      return;
    }
    const sideEffect = async () => {
      const imageFileRef = ref(storage, path);
      const url = await getDownloadURL(imageFileRef);
      setDownloadUrl(url);
    };
    sideEffect();
  }, [path, setDownloadUrl]);

  return downloadUrl;
};
