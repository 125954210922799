import React from "react";
import styled from "styled-components";

const Component = styled.span`
  font-size: 10px;
  height: 16px;
  min-width: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
  padding: 0px 6px;
  color: white;
  background: #1c1c1c;
  border: 1px solid #505050;
  box-shadow: 1px 1px 2px #000000;
  border-radius: 3px;
`;

export const Keycap = ({ label }) => {
  return <Component>{label}</Component>;
};
