import React from "react";
import styled from "styled-components";
import { Text } from "../../design/type";
import { COLORS } from "okthink-core";

const Component = styled.div`
  display: flex;
  margin-left: 40px;
  margin-top: 4px;
`;

const Label = styled(Text)`
  color: ${(props) =>
    props.isCurrentWeek
      ? COLORS.TIMELINE_CURRENT_WEEK.value
      : COLORS.TIMELINE_DAY.value};
  font-family: Lustria, serif;
  font-size: 14px;
  opacity: 0.7;
`;

export const TimelineWeek = ({ day, isCurrentWeek = false }) => {
  const weekText = `WK${day.format("w")}`;
  return (
    <Component>
      <Label isCurrentWeek={isCurrentWeek}>{weekText}</Label>
    </Component>
  );
};
