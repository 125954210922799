import { setDoc } from "firebase/firestore";
import { getTaskListById, isTempId } from "../logic/warpathSlice";
import { checkForItemDoc } from "./checkForItemDoc";
import { getItemRef, getTaskListRef } from "../data/firebase-utils";
import { filterUndefined } from "../logic/filterUndefined";

export const syncTaskListDoc = async ({ store, safeTaskListId, uid }) => {
  const taskList = getTaskListById(store.getState().warpath, safeTaskListId);

  //make sure any other temporary items are also saved.
  for (const itemId of taskList.order) {
    if (isTempId(itemId)) {
      const { meta, ...item } = taskList.itemsMap[itemId];
      const safeItemId = checkForItemDoc(store, uid, safeTaskListId, itemId);
      const docRef = getItemRef(uid, safeTaskListId, safeItemId);
      await setDoc(docRef, item, { merge: true });
    }
  }

  const updatedTaskList = getTaskListById(
    store.getState().warpath,
    safeTaskListId
  );

  const { meta, itemsMap, ...taskListUpdateData } = updatedTaskList;
  const safeData = filterUndefined(taskListUpdateData);
  const taskListRef = getTaskListRef(uid, safeTaskListId);
  return await setDoc(taskListRef, safeData, { merge: true });
};
