import {
  COLORS,
  WARPATH,
  GRAYS,
  BRIGHTS,
  defaultTagColors,
} from "./design/colors";

const WORKSPACE = {};

export { COLORS, WARPATH, GRAYS, BRIGHTS, defaultTagColors, WORKSPACE };
