import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import range from "lodash/range";
import { getDateKey } from "./dateUtils";

const fromNow = (diff) => {
  return dayjs().add(diff, "day");
};

const DAYS_BACK = 15;

const days = range(-DAYS_BACK, 30).map((diff) => fromNow(diff));

const getWeekInfo = (day) => {
  const week = day.subtract(1, "days").week(); // weeks start on monday
  const weekStart = day.startOf("week");

  const dateKeys = [];

  // weeks start on Monday
  for (let i = 1; i < 8; i++) {
    const newDay = weekStart.add(i, "days");
    dateKeys.push(getDateKey(newDay));
  }

  return { week, dateKeys };
};

const getRecentDays = (day) => {
  const r = range(-1, -5);
  const days = r.map((i) => {
    const nextDay = day.add(i, "days");
    return getDateKey(nextDay);
  });
  return days;
};

const initialDay = days[DAYS_BACK];
const initialState = {
  days,
  selectedIndex: DAYS_BACK,
  dateKey: getDateKey(initialDay),
  week: getWeekInfo(initialDay),
  recentDays: getRecentDays(initialDay),
};

export const getSelectedDay = (state) => {
  return state.days[state.selectedIndex];
};

export const getCurrentDateKey = (state) => {
  const day = getSelectedDay(state);
  if (!day) {
    return "";
  }
  const dateKey = day.format("YYYY-MM-DD");
  return dateKey;
};

export const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    goLeft: (state) => {
      if (state.selectedIndex > 0) {
        state.selectedIndex -= 1;
      }
      const day = state.days[state.selectedIndex];
      state.dateKey = getDateKey(day);
      state.week = getWeekInfo(day);
      state.recentDays = getRecentDays(day);
    },
    goRight: (state) => {
      if (state.selectedIndex < state.days.length) {
        state.selectedIndex += 1;
      }
      const day = state.days[state.selectedIndex];
      state.dateKey = getDateKey(day);
      state.week = getWeekInfo(day);
      state.recentDays = getRecentDays(day);
    },

    goToday: (state) => {
      const today = dayjs();
      const dateKey = getDateKey(today);

      const index = state.days.findIndex((dayItem) => {
        const testDateKey = getDateKey(dayItem);
        return testDateKey === dateKey;
      });
      state.selectedIndex = index;

      state.dateKey = dateKey;
      state.week = getWeekInfo(today);
      state.recentDays = getRecentDays(today);
    },
  },
});
export const { setDays, goLeft, goRight, goToday } = timelineSlice.actions;

export const timelineReducer = timelineSlice.reducer;
