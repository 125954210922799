import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedDay } from "okthink-core/logic/timelineSlice";
import { TaskList } from "./TaskList";
import { KeyEngine } from "okthink-core/logic/keyEngine";
import { useAuthState } from "okthink-core/data/auth";
import { Panel } from "../../components/Panel";
import { createSelector } from "@reduxjs/toolkit";
import { WARPATH } from "okthink-core";

import { loadTaskListAtDateKey } from "okthink-core/operations/loadTaskListAtDateKey";

const Component = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${WARPATH.GREY1};
  font-family: "Work Sans", sans-serif;
  border: 1px solid transparent;
  pointer-events: none;
`;

const MainPanel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px;
  padding-bottom: 0px;
  gap: 10px;
  justify-content: center;
  transition: all 0.5s;
`;

const Notebook = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  max-width: 1600px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  filter: drop-shadow(0px 0px 10px rgba(242, 240, 221, 0.2));
  margin: 10px;
`;

const MainColumn = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  gap: 20px;
  max-width: 1400px;
  justify-content: center;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 800px;
`;

const selectWorkspaceData = createSelector(
  [
    (state) => state.workspace.cursor.editing,
    (state) => state.workspace.viewOptions.focusMode,
  ],
  (editing, focusMode) => {
    return {
      editing,
      focusMode,
    };
  }
);

const selectTimelineData = createSelector(
  [
    (state) => getSelectedDay(state.timeline),
    (state) => {
      const day = getSelectedDay(state.timeline);
      const dateKey = day.format("YYYY-MM-DD");
      return dateKey;
    },
    (state) => {
      const day = getSelectedDay(state.timeline);
      const dateKey = day.format("YYYY-MM-DD");
      const dateKeyTaskListId = state.warpath.dateKeys[dateKey];
      return dateKeyTaskListId;
    },
  ],
  (day, dateKey, dateKeyTaskListId) => {
    if (!day) {
      return "";
    }
    const year = day.format("YYYY");
    const week = day.format("w");
    const dayTitle = day.format("dddd, MMMM DD, YYYY");

    return {
      dateKey,
      dateKeyTaskListId,
      dateLabel: day.format("MMM DD, YYYY"),
      year,
      week,
      dayTitle,
    };
  }
);

export const WarPath = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const uid = useAuthState();

  const { focusMode } = useSelector(selectWorkspaceData);

  const { dateKey, dateKeyTaskListId, dayTitle } =
    useSelector(selectTimelineData);

  useEffect(() => {
    window.addEventListener("paste", KeyEngine.handlePaste);
    return () => {
      window.removeEventListener("paste", KeyEngine.handlePaste);
    };
  }, []);

  // load a taskList for this id.
  useEffect(() => {
    // there's already a taskList for this datekey, no need to load.
    if (!uid || !dateKey || dateKeyTaskListId) {
      return;
    }
    loadTaskListAtDateKey(uid, dateKey);
  }, [dispatch, uid, dateKey, dateKeyTaskListId]);

  return (
    <Component ref={componentRef} tabIndex={0}>
      <MainPanel>
        <Notebook>
          <MainColumn>
            {!focusMode && (
              <Column>
                <TaskList title="Backlog" columnPosition={0} />
              </Column>
            )}
            <Column>
              <TaskList
                title={dayTitle}
                currentDateKey={dateKey}
                columnPosition={1}
              />
            </Column>
          </MainColumn>
        </Notebook>
      </MainPanel>
    </Component>
  );
};
