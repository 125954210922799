export const COLORS = {
  BASE: {
    name: "BASE",
    description: "Primary Background Color",
    value: "rgba(10, 7, 18, 1)",
  },
  BASE_TRANSPARENT: {
    name: "BASE_TRANSPARENT",
    description: "Primary Background Color",
    value: "rgba(10, 7, 18, .3)",
  },
  BASE2: {
    name: "BASE2",
    description: "Secondary Background Color",
    value: "rgb(19, 8, 41)",
  },
  BASE3: {
    name: "BASE3",
    description: "Tertiery Background Color",
    value: "rgba(47, 11, 69, 1)",
  },
  BASE4: {
    name: "BASE4",
    description: "Contrast background color",
    value: "rgba(92, 91, 99, 1)",
  },
  WHITE: {
    name: "WHITE",
    description: "Regular text color",
    value: "rgba(244, 241, 208, 1)",
  },
  NOTE_TEXT: {
    name: "NOTE_TEXT",
    description: "Text color for notes",
    value: "rgba(137, 137, 137, 1)",
  },
  YELLOW: {
    name: "YELLOW",
    description: "Highlight color",
    value: "rgba(204, 165, 65, 1)",
  },
  GREEN: {
    name: "GREEN",
    description: "Positive/Afternoon color",
    value: "rgba(93, 146, 52, 1)",
  },
  TEAL: {
    name: "TEAL",
    description: "Evening color",
    value: "rgba(66, 154, 168, 1)",
  },
  PURPLE: {
    name: "PURPLE",
    description: "Night color",
    value: "rgba(82, 52, 148, 1)",
  },
  PINK: {
    name: "PINK",
    description: "Tomorrow color",
    value: "rgba(150, 14, 115, 1)",
  },
  PURPLER: {
    name: "PURPLER",
    description: "Support Color",
    value: "rgba(164, 55, 182, 1)",
  },
  RED: {
    name: "RED",
    description: "Negative color",
    value: "rgb(193, 28, 58)",
  },
  SALMON: {
    name: "SALMON",
    description: "Highlight color",
    value: "#FFC5C7",
  },
  V2_BLUE: {
    name: "V2_BLUE",
    description: "Panel background",
    value: "#13071E",
  },
  V2_BASE: {
    name: "V2_BASE",
    description: "Main background color",
    value: "#281C38",
  },
  TIMELINE_ACTIVE: {
    name: "TIMELINE_ACTIVE",
    description: "Highlight color for timeline",
    value: "#E5DEB7",
  },
  TIMELINE_DAY: {
    name: "TIMELINE_DAY",
    description: "Default color for days in the timeline",
    value: "#8D78A9",
  },
  TIMELINE_WEEKEND: {
    name: "TIMELINE_WEEKEND",
    description: "Default color for days in the timeline",
    value: "#5F499F",
  },
  TIMELINE_CURRENT_WEEK: {
    name: "TIMELINE_CURRENT_WEEK",
    description: "Default color for days in the timeline",
    value: "#FFFFFF",
  },
  TODAY_HEADER: {
    name: "TODAY_HEADER",
    description: "color of the header of today panel",
    value: "#EFEFD3",
  },
  PANEL_HEADER: {
    name: "PANEL_HEADER",
    description: "color of panel header",
    value: "#C1BFC4",
  },
  ACTIVE_ITEM_BORDER: {
    name: "ACTIVE_ITEM_BORDER",
    description: "color of item cursor",
    value: "#7FC9E0",
  },
  IMPORTANT_ITEM: {
    name: "IMPORTANT_ITEM",
    description: "color of important item",
    value: "rgba(158, 22, 79, 0.23)",
  },
  ACTIVE_DAY_BACKGROUND: {
    name: "ACTIVE_DAY_BACKGROUND",
    description: "background of active day",
    value: "#6B1D5C",
  },
  HABIT_TITLE: {
    name: "ACTIVE_DAY_BACKGROUND",
    description: "background of active day",
    value: "#83B5D1",
  },
  LINK: {
    name: "LINK",
    description: "link for urls",
    value: "#a2d4ed",
  },
  FOOTER_BACKGROUND: {
    name: "FOOTER_BACKGROUND",
    description: "Footer background color",
    value: "#191225",
  },
};

export const GRAYS = [
  COLORS.BASE,
  COLORS.BASE2,
  COLORS.BASE3,
  COLORS.BASE4,
  COLORS.WHITE,
  COLORS.NOTE_TEXT,
];
export const BRIGHTS = [
  COLORS.YELLOW,
  COLORS.GREEN,
  COLORS.TEAL,
  COLORS.PURPLE,
  COLORS.PINK,
  COLORS.PURPLER,
  COLORS.RED,
];

export const WARPATH = {
  BASE: "#090000",
  BASE2: COLORS.BASE.value,
  ORANGE: "#CA6A3C",
  RED: COLORS.RED.value,
  GREY1: "#859B9A",
  GREY2: "#789485",
  GREY3: "#4E7D7A",
};

export const defaultTagColors = [
  COLORS.GREEN.value,
  COLORS.YELLOW.value,
  COLORS.TEAL.value,
  COLORS.PURPLE.value,
  COLORS.PINK.value,
  COLORS.PURPLER.value,
  COLORS.RED.value,
];
