import React from "react";
import styled from "styled-components";
import { Text } from "../../design/type";
import { COLORS } from "okthink-core";
import { Keycap } from "./Keycap";

const Component = styled.div`
  display: flex;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid transparent;
  border-color: ${(props) =>
    props.isActive ? COLORS.ACTIVE_ITEM_BORDER.value : "transparent"};
  align-items: center;
`;

export const PlaceholderItem = ({ isActive = false }) => {
  const label1 = "Press";
  const label2 = "to create a new item";
  return (
    <Component isActive={isActive}>
      <Text>{label1}</Text>
      <Keycap label="o" />
      <Text>{label2}</Text>
    </Component>
  );
};
