import { createSelector } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Help } from "./components/Help";
import { useAuthState } from "okthink-core/data/auth";
import { reqNotebook } from "okthink-core/data/loaders";
import { COLORS, WARPATH } from "okthink-core";
import { getHabitsOrder } from "okthink-core/logic/notebook/getHabitsOrder";
import { setNotebook } from "okthink-core/logic/notebook/notebookSlice";
import { setHabitIds } from "okthink-core/logic/warpathSlice";
import { setBacklogTaskListId } from "okthink-core/logic/workspaceSlice";
import { Settings } from "./screens/Settings";
import { WarPath } from "./screens/WarPath";
import { Timeline } from "./screens/WarPath/Timeline";
import { Login } from "./components/Login";
import { WeeklyBackground } from "./screens/WarPath/WeeklyBackground";
import { KeyEngine } from "okthink-core/logic/keyEngine";
import { Drawer } from "./dev/Drawer";

const Component = styled.div`
  position: relative;
  background-color: ${COLORS.V2_BASE.value};
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;

  &:focus {
    border: 0px solid transparent;
    outline: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0;
`;

const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${WARPATH.GREY1};
  font-family: "Work Sans", sans-serif;
  border: 1px solid transparent;
`;

const selectWorkspaceData = createSelector(
  [
    (state) => state.workspace.viewOptions.helpLevel,
    (state) => state.workspace.viewOptions.showSettings,
    (state) => state.workspace.viewOptions.showDevGuide,
    (state) => state.workspace.cursor.editing,
  ],
  (helpLevel, showSettings, showDevGuide, editing) => ({
    helpLevel,
    showSettings,
    showDevGuide,
    editing,
  })
);

export const Home = () => {
  const { editing, showSettings, helpLevel } = useSelector(selectWorkspaceData);

  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const uid = useAuthState();

  useEffect(() => {
    if (!uid) {
      return;
    }

    const loader = async () => {
      const notebook = await reqNotebook(uid);
      if (!notebook) {
        console.warn("notebook not found, might not be initialized");
        return;
      }

      const habitIds = getHabitsOrder(notebook);

      dispatch(setHabitIds({ habitIds }));
      dispatch(setNotebook({ notebook }));
      const { backlogTaskListId } = notebook;
      if (backlogTaskListId) {
        dispatch(setBacklogTaskListId({ backlogTaskListId }));
      }
      setLoaded(true);
      // just focus when everything is loaded
      componentRef.current.focus();
    };
    loader();
  }, [dispatch, uid, setLoaded]);

  const componentRef = useRef();
  useEffect(() => {
    if (!editing && componentRef.current) {
      componentRef.current.focus();
    }
  }, [editing]);

  if (!uid) {
    return (
      <LoginContainer>
        <Login />
      </LoginContainer>
    );
  }

  if (!loaded) {
    return null;
  }

  const onKeyDown = KeyEngine.getHandler();
  const showFullHelp = helpLevel === 2;
  return (
    <Component onKeyDown={onKeyDown} ref={componentRef} tabIndex={0}>
      <Timeline />
      <WeeklyBackground />
      <MainContent>
        {showSettings ? <Settings /> : <WarPath />}
        {showFullHelp && <Help />}
      </MainContent>
      <Drawer />
    </Component>
  );
};
