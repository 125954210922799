import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { Footer } from "../screens/WarPath/Footer";
import styled, { keyframes, css } from "styled-components";
import { CheatSheets } from "./CheatSheets";

const slideInBounceFadeIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0);
  }
`;

const Component = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  animation: ${(props) =>
    props.shouldAnimate
      ? css`
          ${slideInBounceFadeIn} .4s ease-in-out
        `
      : "none"};
`;

const selectWorkspaceData = createSelector(
  [(state) => state.workspace.viewOptions.showDevGuide],
  (showDevGuide) => showDevGuide
);
export const Drawer = () => {
  const showDevGuide = useSelector(selectWorkspaceData);
  return (
    <Component shouldAnimate={showDevGuide}>
      <Footer />
      <CheatSheets on={showDevGuide} />
    </Component>
  );
};
