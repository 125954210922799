import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskLists: [],
};

export const debugTaskLists = createSlice({
  name: "debugTaskLists",
  initialState,
  reducers: {
    setTaskLists: (state, action) => {
      const { taskLists } = action.payload;
      state.taskLists = taskLists;
    },
    taskListDeleted: (state, action) => {
      const { taskListId } = action.payload;
      const index = state.taskLists.findIndex(
        (item) => item.meta.id === taskListId
      );
      state.taskLists.splice(index, 1);
    },
  },
});

export const { setTaskLists, taskListDeleted } = debugTaskLists.actions;
export const debugTaskListsReducer = debugTaskLists.reducer;
