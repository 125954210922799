import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";

import { Button } from "../../components/Button";
import { cleanData } from "okthink-core/data/firebase-fixes";
import { useSelector, useDispatch } from "react-redux";
import {
  getColumnDelegate,
  getCursorContext,
} from "okthink-core/logic/keyEngine";
import { useAuthState, firebaseSignout } from "okthink-core/data/auth";
import { clearUser } from "okthink-core/logic/userSlice";
import get from "lodash/get";

const Component = styled.div`
  height: 25px;
  background-color: ${COLORS.FOOTER_BACKGROUND.value};
  color: ${COLORS.WHITE.value};
  display: flex;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  padding: 0px 10px;
  z-index: 2;
`;

const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const UserId = styled.div`
  margin-left: 5px;
`;
const CursorInfo = styled.div``;
const DataErrors = styled.div`
  position: absolute;
  bottom: 20px;
  right: 5px;
  width: 175px;
  background-color: ${COLORS.BASE3.value};
  color: ${COLORS.WHITE.value};
  white-space: pre-wrap;
  font-size: 10px;
`;

const ErrorHeader = styled.div`
  display: flex;
  background-color: ${COLORS.BASE2.value};
  padding: 10px;
  justify-content: space-between;
`;

const ErrorBody = styled.div`
  height: 400px;
  overflow: scroll;
  padding: 10px;
`;

const Label = styled.div``;

const Logout = styled.div`
  color: ${COLORS.WHITE.value};
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Footer = () => {
  const { cursor, keyStrokes } = useSelector((state) => state.workspace);
  const { x, y } = cursor;

  const taskList = useSelector((state) => {
    const { columnId, columnDelegate } = getCursorContext(state);
    return columnDelegate.getColumn(columnId);
  });

  const itemId = useSelector((state) => {
    const { itemId } = getCursorContext(state);
    return itemId;
  });

  const columnLengths = useSelector((state) => {
    return state.workspace.columns.map(({ columnId, type }) => {
      const delegate = getColumnDelegate(state.workspace);
      return delegate.getColumnSize(columnId);
    });
  });

  const uid = useAuthState();

  const columnsString = columnLengths.join(",");

  const cursorInfo = `${itemId} (${x},${y}), [${columnsString}]`;

  const actions = get(taskList, "meta.dataErrors.actions", null);

  const hasErrors =
    actions &&
    (actions.docsNotFound.length > 0 || actions.orphanedDocs.length > 0);

  const onClick = () => {
    cleanData(
      taskList.meta.dataErrors.actions,
      taskList.order,
      uid,
      taskList.meta.id
    );
  };

  let errorWindow;
  if (hasErrors) {
    const { actions, summary } = taskList.meta.dataErrors;
    const { docsNotFound, orphanedDocs } = actions;
    const numErrors = docsNotFound.length + orphanedDocs.length;
    errorWindow = (
      <DataErrors>
        <ErrorHeader>
          <Label>{`${numErrors} errors`}</Label>
          <Button label={"fix"} appearance="small" onClick={onClick} />
        </ErrorHeader>
        <ErrorBody>{summary}</ErrorBody>
      </DataErrors>
    );
  }

  const dispatch = useDispatch();
  const logout = async () => {
    await firebaseSignout();
    dispatch(clearUser());
  };

  return (
    <Component>
      {errorWindow}
      <Left>
        okthink
        <UserId>{uid}</UserId>
      </Left>
      <Center>{keyStrokes.join("")}</Center>
      <Right>
        <CursorInfo>{cursorInfo}</CursorInfo>
        <Logout onClick={logout}>Log out</Logout>
      </Right>
    </Component>
  );
};
