import { useEffect } from "react";
import { setTaskList, isTempId, getVisibleOrder } from "./warpathSlice";
import { useSelector, useDispatch } from "react-redux";
import { reqTaskList } from "../data/loaders";
import { useAuthState } from "../data/auth";
import { createSelector } from "@reduxjs/toolkit";
import { getTaskListTagSummary } from "./getTaskListTagSummary";

const getStats = (taskList) => {
  if (!taskList) {
    return { numCompleted: null, totalItems: null };
  }
  const numCompleted = taskList
    ? taskList.order.reduce((prev, next) => {
        const item = taskList.itemsMap[next];
        if (!item) {
          console.warn("numCompleted: ", next, " does not exist in itemsMap");
          return prev;
        }

        return prev + (item.todo && item.value ? 1 : 0);
      }, 0)
    : null;
  const totalItems = taskList.order.length;

  // sets totalTodoItems by counting the number of items with todo: true
  const totalTodoItems = taskList.order.reduce((prev, next) => {
    const item = taskList.itemsMap[next];
    if (!item) {
      return prev;
    }
    return prev + (item.todo ? 1 : 0);
  }, 0);

  const numPunted = taskList
    ? taskList.order.reduce((prev, next) => {
        const item = taskList.itemsMap[next];
        if (!item) {
          console.warn("--numPunted--: ", next, " does not exist in itemsMap");
          return prev;
        }

        return prev + (item.todo && item.punt ? 1 : 0);
      }, 0)
    : null;

  return { numCompleted, numPunted, totalItems, totalTodoItems };
};

const selectTaskListData = createSelector(
  [
    (state) => state,
    (state, taskListId, tagIndex) => tagIndex,
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      return taskList;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      const { numCompleted } = getStats(taskList);
      return numCompleted;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      const { totalItems } = getStats(taskList);
      return totalItems;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      const { totalTodoItems } = getStats(taskList);
      return totalTodoItems;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      const { numPunted } = getStats(taskList);
      return numPunted;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      return !!taskList;
    },
    (state, taskListId) => {
      const taskList = state.warpath.taskLists[taskListId];
      if (!taskList) {
        return [];
      }
      const tagSummary = getTaskListTagSummary(taskList);
      return tagSummary;
    },
  ],
  (
    state,
    tagIndex,
    taskList,
    numCompleted,
    totalItems,
    totalTodoItems,
    numPunted,
    taskListLoaded,
    tagSummary
  ) => {
    let visibleOrder = [];

    if (taskList) {
      const { hideComplete } = state.workspace.viewOptions;
      const tagInfo = tagSummary[tagIndex];
      const { tag } = tagInfo;

      visibleOrder = getVisibleOrder(
        state.warpath,
        taskList.meta.id,
        hideComplete,
        tag
      );
    }

    return {
      taskList,
      numCompleted,
      totalItems,
      totalTodoItems,
      numPunted,
      visibleOrder,
      taskListLoaded,
      tagSummary,
    };
  },
  {
    memoizeOptions: {
      maxSize: 10, // this selector will get called with parameters, that's why
    },
  }
);

export const useTaskList = ({ taskListId, tagIndex }) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => {
    return selectTaskListData(state, taskListId, tagIndex);
  });

  const { taskListLoaded } = data;
  const uid = useAuthState();

  // pt1, the loader
  useEffect(() => {
    const loader = async () => {
      if (!uid) {
        console.log("no uid");
        return;
      }
      if (!taskListId) {
        console.log("no taskListId");
        return;
      }

      if (isTempId(taskListId)) {
        return;
      }

      // check if the taskList is already loaded.
      if (taskListLoaded) {
        return;
      }

      const data = await reqTaskList(uid, taskListId);

      const { meta, itemsMap, order, date, dateKey } = data;

      dispatch(
        setTaskList({
          meta,
          itemsMap,
          date,
          dateKey,
          order,
        })
      );
    };
    loader();
  }, [dispatch, uid, taskListLoaded, taskListId]);

  return data;
};
