import { dateKeyRange } from "../dateUtils";
import { getTaskListAtDateKey } from "./getTaskListAtDateKey";
import { isIncomplete } from "./isIncomplete";

export const getRecentIncompletesSummary = (
  state,
  dateKey,
  rangeStart,
  rangeEnd
) => {
  const recentDays = dateKeyRange(dateKey, rangeStart, rangeEnd);
  const incompletesSummary = [];

  recentDays.forEach((dateKey) => {
    const taskList = getTaskListAtDateKey(state, dateKey);

    if (!taskList) return;

    const { id: taskListId } = taskList.meta;

    const incompleteIds = taskList.order.filter((itemId) => {
      const item = taskList.itemsMap[itemId];
      if (!item) {
        console.warn(
          `um, ${dateKey}(${taskListId}) ${itemId} is not found in itemsMap, but it's in the order`
        );
        return false;
      }
      return isIncomplete(item);
    });

    if (incompleteIds.length === 0) {
      return;
    }

    const incompleteTaskListSummary = {
      dateKey,
      taskListId,
      incompleteIds,
    };
    incompletesSummary.push(incompleteTaskListSummary);
  });

  return incompletesSummary;
};
