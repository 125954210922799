import { createSlice } from "@reduxjs/toolkit";
import { getTags } from "../getTags";
import { defaultTagColors } from "../../design/colors";

const initialState = {
  weeklyBackgrounds: {},
  backlogTaskListId: null,
  tags: [],
  dirtyTags: false,
  // in the form of
  // {title: "title", completions: {}}
  habitsMap: {},
  habitsOrder: [],
  archivedHabits: [],
  tagColorMap: {},
  specialDates: {},
};

export const getBacklogTaskListId = (state) => {
  return state.backlogTaskListId || "BACKLOG";
};

export const NEW_HABIT_DRAFT = "NEW_DRAFT";

export const getHabitText = (notebookState, habitId) => {
  const { habitsMap } = notebookState;
  const habit = habitsMap[habitId];
  return habit.text;
};

export const getHabit = (notebookState, habitId) => {
  const { habitsMap } = notebookState;
  const habit = habitsMap[habitId];
  return habit;
};

export const saveTagsToNotebook = (notebookState, tagLabelsToCheck) => {
  const { tagColorMap, tags = [] } = notebookState;
  let nextColorIndex = tags.length % defaultTagColors.length;
  tagLabelsToCheck.forEach((label) => {
    if (!tagColorMap[label]) {
      const newColor = defaultTagColors[nextColorIndex];
      notebookState.tagColorMap[label] = newColor;
      const newTag = { label, color: newColor };
      notebookState.tags.push(newTag);
      notebookState.dirtyTags = true;
      nextColorIndex = (nextColorIndex + 1) % defaultTagColors.length;
    }
  });
};

export const notebookSlice = createSlice({
  name: "notebook",
  initialState,
  reducers: {
    setNotebook: (state, action) => {
      const { notebook } = action.payload;
      const { tags = [], habitsMap, habitsOrder = [] } = notebook;

      const tagColorMap = {};
      tags.forEach((tag, index) => {
        const { label, color } = tag;
        const safeColor = color ? color : defaultTagColors[index];
        tagColorMap[label] = safeColor;
      });
      return {
        ...initialState,
        tags,
        habitsMap,
        ...notebook,
        tagColorMap,
        habitsOrder,
      };
    },
    setWeekBackground: (state, action) => {
      const { weekKey, path } = action.payload;
      state.weeklyBackgrounds[weekKey] = path;
    },
    commitHabitText: (state, action) => {
      const { habitId, value, tags, newId } = action.payload;
      const { habitsMap, habitsOrder } = state;
      const habit = habitsMap[habitId];
      habit.text = value;
      habit.tags = tags;

      saveTagsToNotebook(state, tags);

      if (newId) {
        const replaceIndex = habitsOrder.indexOf(habitId);
        habitsOrder.splice(replaceIndex, 1, newId);
        habit.meta = { id: newId };
        habitsMap[newId] = habit;
        delete habitsMap[habitId];
      }
    },
    clearDraftHabit: (state) => {
      const { habitsMap, habitsOrder } = state;
      const replaceIndex = habitsOrder.indexOf(NEW_HABIT_DRAFT);
      habitsOrder.splice(replaceIndex, 1);
      delete habitsMap[NEW_HABIT_DRAFT];
    },
    insertHabitAfter: (state, action) => {
      const { habitId } = action.payload;
      const { habitsOrder, habitsMap } = state;
      const index = habitsOrder.indexOf(habitId);
      const newHabitId = NEW_HABIT_DRAFT;
      const newItem = { text: "", completions: {} };
      const newY = index + 1;

      habitsOrder.splice(newY, 0, newHabitId);
      habitsMap[newHabitId] = newItem;
    },
    addNewHabit: (state, action) => {
      const { habitsOrder, habitsMap } = state;
      const newHabitId = NEW_HABIT_DRAFT;
      const newItem = { text: "", completions: {} };
      habitsOrder.push(newHabitId);
      habitsMap[newHabitId] = newItem;
    },
    archiveHabit: (state, action) => {
      const { habitId } = action.payload;
      const { habitsOrder, habitsMap } = state;
      const index = habitsOrder.indexOf(habitId);
      habitsOrder.splice(index, 1);
      state.archivedHabits.push(habitId);
      habitsMap[habitId].archived = true;
    },
    unarchiveHabit: (state, action) => {
      const { habitId } = action.payload;
      const { habitsOrder, archivedHabits, habitsMap } = state;
      const index = archivedHabits.indexOf(habitId);
      archivedHabits.splice(index, 1);
      habitsOrder.push(habitId);
      habitsMap[habitId].archived = false;
    },
    moveHabitUp: (state, action) => {
      const { habitId } = action.payload;
      const { habitsOrder } = state;
      const index = habitsOrder.indexOf(habitId);
      if (index > 0) {
        const swapItem = habitsOrder[index - 1];
        habitsOrder.splice(index - 1, 2, habitId, swapItem);
      }
    },
    moveHabitDown: (state, action) => {
      const { habitId } = action.payload;
      const { habitsOrder } = state;
      const index = habitsOrder.indexOf(habitId);
      console.log("moveHabitDown", habitId, index);
      if (index < habitsOrder.length - 1) {
        const swapId = habitsOrder[index + 1];
        habitsOrder.splice(index, 2, swapId, habitId);
      }
    },
    setHabitCompletion: (state, action) => {
      const { habitId, dateKey, nextValue, modified } = action.payload;
      const habit = state.habitsMap[habitId];
      const { completions } = habit;
      const habitTrack = {
        done: nextValue,
        modified,
      };
      completions[dateKey] = habitTrack;
    },
    resetDirtyTags: (state) => {
      state.dirtyTags = false;
    },
  },
  extraReducers: {
    "warpath/commit": (state, action) => {
      const { newValue } = action.payload;
      const tagLabelsToCheck = getTags(newValue);
      saveTagsToNotebook(state, tagLabelsToCheck);
    },
  },
});

export const {
  setNotebook,
  setWeekBackground,
  commitHabitText,
  moveHabitDown,
  moveHabitUp,
  insertHabitAfter,
  clearDraftHabit,
  addNewHabit,
  archiveHabit,
  unarchiveHabit,
  setHabitCompletion,
  resetDirtyTags,
} = notebookSlice.actions;
export const notebookReducer = notebookSlice.reducer;
