import { COLORS } from "okthink-core";
import React from "react";
import styled from "styled-components";
import { Keycap } from "../screens/WarPath/Keycap";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.2);
  gap: 4px;
`;

const KeyStroke = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  color: ${COLORS.WHITE.value};
  font-size: 10px;
`;

export const KeyBind = ({ shortcut }) => {
  const { description, helpKeys, stroke } = shortcut;
  const keyStrokes = helpKeys ? helpKeys : [stroke];

  return (
    <Component>
      <KeyStroke>
        {keyStrokes.map((key, index) => {
          const reactKey = `${key}${index}`;

          if (key === "+") {
            return "+";
          }
          return <Keycap key={reactKey} label={key} />;
        })}
      </KeyStroke>
      <Description>{description}</Description>
    </Component>
  );
};
