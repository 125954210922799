import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { COLORS } from "okthink-core";
import { themeVal } from "../design/themeVal";

export const BUTTON_SMALL = "BUTTON_SMALL";

const Component = styled.div`
  background-color: ${themeVal("backgroundColor")};
  color: ${COLORS.WHITE.value};
  width: ${themeVal("width")};
  height: ${themeVal("height")};
  padding: ${themeVal("padding")};
  margin: ${themeVal("margin")};
  text-align: center;
  font-family: Work Sans;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const THEMES = {
  default: {
    backgroundColor: COLORS.PURPLE.value,
    width: "120px",
    height: "20px",
    padding: "5px",
    margin: "0px 10px",
  },
  small: {
    backgroundColor: COLORS.PINK.value,
    width: "50px",
    height: "14px",
    padding: "2px",
    margin: "0px",
  },
};

export const Button = ({ onClick, label, appearance = "default" }) => {
  return (
    <ThemeProvider theme={THEMES[appearance]}>
      <Component onClick={onClick}>{label}</Component>
    </ThemeProvider>
  );
};
