export const getTags = (str) => {
  if (!str) {
    return [];
  }
  const test = str.match(/#\w+/gi);
  if (!test) {
    return [];
  }
  return test.map((x) => x.substr(1).toLowerCase()) || [];
};
