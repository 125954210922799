import { doc } from "firebase/firestore";
import { isTempId, replaceTaskListId } from "../logic/warpathSlice";
import { getTaskListsRef } from "../data/firebase-utils";

export const checkForTaskListDoc = (store, uid, taskListId) => {
  // TODO: here check if the list exists in the first place.
  if (isTempId(taskListId)) {
    const tempTaskListId = taskListId;
    const taskListsCollection = getTaskListsRef(uid);
    const newDoc = doc(taskListsCollection);
    const newTaskListId = newDoc.id;
    store.dispatch(
      replaceTaskListId({
        tempTaskListId,
        newTaskListId,
      })
    );
    return newTaskListId;
  }
  return taskListId;
};
