import { updateDoc, getDocs, deleteField, deleteDoc } from "firebase/firestore";
import { getTaskListRef, getItemsQuery } from "./firebase-utils";

const errorCollector = () => {
  const docsNotFound = [];
  const orphanedDocs = [];
  return {
    docNotFound: (id) => {
      docsNotFound.push(id);
    },
    orphanedDoc: (item) => {
      orphanedDocs.push(item);
    },
    getMessage: () => {
      return [
        "Not Found", //
        ...docsNotFound.map((id) => `  ${id}`),
        "Not Registered", //
        ...orphanedDocs.map((item) => `  ${item.meta.id}`),
      ].join("\n");
    },
    getActions: () => {
      return { docsNotFound, orphanedDocs };
    },
  };
};

export const fixOrder = async (uid, taskListId) => {
  console.log("--fixOrder--", uid);

  const taskListRef = getTaskListRef(uid, taskListId);
  const itemsQuery = getItemsQuery(uid, taskListId);
  const querySnapshot = await getDocs(itemsQuery);

  const order = [];

  // enforce schema.
  querySnapshot.forEach((itemSnap /*QueryDocumentSnapshot */) => {
    order.push(itemSnap.id);
    updateDoc(itemSnap.ref, {
      order: deleteField(),
      id: deleteField(),
    });
  });

  //set the order on the today doc
  updateDoc(taskListRef, {
    order,
  });
};

export const cleanData = async (actions, order, uid, taskListId) => {
  const { docsNotFound, orphanedDocs } = actions;
  const cleanOrder = order.filter((id) => !docsNotFound.includes(id));
  console.log("docsNotFound", docsNotFound);
  console.log("order:", order.length, order);
  console.log("newOrder", cleanOrder.length, cleanOrder);

  orphanedDocs.forEach((item) => {
    cleanOrder.push(item.meta.id);
  });

  const taskListRef = getTaskListRef(uid, taskListId);

  updateDoc(taskListRef, {
    order: cleanOrder,
  });
};

export const checkData = ({ itemsMap, order = [] }) => {
  const errors = errorCollector();

  // check order
  order.forEach((itemId) => {
    if (!itemsMap[itemId]) {
      errors.docNotFound(itemId);
    }
  });

  // check items
  Object.keys(itemsMap).forEach((itemKey) => {
    if (!order.includes(itemKey)) {
      errors.orphanedDoc(itemsMap[itemKey]);
    }
  });

  return {
    summary: errors.getMessage(),
    actions: errors.getActions(),
  };
};

export const deleteTaskList = async (uid, taskListId) => {
  if (!uid) {
    console.log("failed safety check");
    return;
  }
  const taskListRef = getTaskListRef(uid, taskListId);
  console.log("deleting", taskListRef.path);
  return deleteDoc(taskListRef);
};
