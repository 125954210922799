import { setDoc } from "firebase/firestore";
import { getItem } from "../logic/warpathSlice";
import { checkForTaskListDoc } from "./checkForTaskListDoc";
import { checkForItemDoc } from "./checkForItemDoc";
import { getItemRef } from "../data/firebase-utils";
import { syncTaskListDoc } from "./syncTaskListDoc";

export const syncItemDoc = async ({ store, uid, taskListId, itemId }) => {
  const item = getItem(store.getState().warpath, taskListId, itemId);
  const { meta, ...cleanItem } = item;

  const safeTaskListId = checkForTaskListDoc(store, uid, taskListId);
  const safeItemId = checkForItemDoc(store, uid, safeTaskListId, item.meta.id);
  const docRef = getItemRef(uid, safeTaskListId, safeItemId);

  await setDoc(docRef, cleanItem, { merge: true });
  return await syncTaskListDoc({ store, safeTaskListId, uid });
};
