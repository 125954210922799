import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { Text } from "../design/type";

const Component = styled.div`
  background-color: ${(props) =>
    props.active ? COLORS.PINK.value : COLORS.BASE3.value};
  padding: 6px;
  color: ${COLORS.WHITE.value};
  margin-left: 5px;
  border-radius: 2px;
  cursor: pointer;
`;

export const TabButton = ({ onClick, label, active }) => {
  return (
    <Component onClick={onClick} active={active}>
      <Text>{label}</Text>
    </Component>
  );
};
