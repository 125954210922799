import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";

const Component = styled.div`
  position: relative;
  width: 52px;
  min-height: 45px;
  z-index: 100;
  margin-top: -10px;
`;

const DaysLeftLabel = styled.div`
  background-color: ${COLORS.BASE.value};
  color: ${COLORS.WHITE.value};
  font-family: Lustria, serif;
  font-size: 10px;
  border-radius: 4px;
  position: absolute;
  white-space: nowrap;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-8deg);
  padding: 1px 4px;
`;

const Emoji = styled.div`
  position: absolute;
  font-size: 12px;
  ${(props) => props.position};
`;

const flower1 = { left: 9, top: 3 };
const flower2 = { left: 27, top: 0 };
const flower3 = { left: 2, top: 12 };
const flower4 = { left: 19, top: 8, zIndex: 20 };
const flower5 = { left: 37, top: 8 };
const korea = { left: 7, top: 6, fontSize: 19 };
const japan = { left: 26, top: 3, fontSize: 19 };

const japanEmoji = "🇯🇵";
const koreaEmoji = "🇰🇷";

export const JapanTrip = ({ daysUntil }) => {
  return (
    <Component>
      <Emoji position={flower1}>🌸</Emoji>
      <Emoji position={flower2}>🌸</Emoji>
      <Emoji position={flower3}>🌸</Emoji>
      <Emoji position={flower4}>🌸</Emoji>
      <Emoji position={flower5}>🌸</Emoji>
      <Emoji position={japan}>{japanEmoji}</Emoji>
      <Emoji position={korea}>{koreaEmoji}</Emoji>
      <DaysLeftLabel>in {daysUntil} days</DaysLeftLabel>
    </Component>
  );
};

// <Emoji>🇯🇵</Emoji>
// <Emoji>🇰🇷</Emoji>
// <Emoji>🌸</Emoji>
// <Emoji>🌸</Emoji>
// <Emoji>🌸</Emoji>
// <Emoji>🌸</Emoji>
