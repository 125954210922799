export const timeOfDayColors = {
  morning: "orange",
  afternoon: "springGreen",
  workday: "dodgerBlue",
  evening: "chocolate",
  night: "darkOrchid",
};

export const getTimeOfDayBorder = (tags = []) => {
  const relevantTag = tags.find((tag) => {
    return timeOfDayColors[tag] !== undefined;
  });

  if (!relevantTag) {
    return null;
  }

  const color = timeOfDayColors[relevantTag];
  return color;
};
