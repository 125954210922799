import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../logic/userSlice";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  // sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import { query, collection, addDoc, getDocs, where } from "firebase/firestore";
import { app, db } from "../data/firebase";

if (!app) {
  //data/firebase.js needs to be loaded first
  throw new Error("Yo, firebase app is supposed to be initialized");
}

const googleProvider = new GoogleAuthProvider();
const auth = getAuth(app);

export const firebaseSignout = () => {
  return signOut(auth);
};

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // how to initialize a user right here. Query if the things exist, and if not, create the docs necessary.
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const useAuthState = () => {
  const uid = useSelector((state) => (state.user ? state.user.uid : null));
  const dispatch = useDispatch();

  useEffect(() => {
    const clearListener = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          dispatch(setUser(user));
        } else {
          console.log("no user logged in");
        }
      },
      () => {
        console.log("there as an error getting auth");
      }
    );

    return () => {
      clearListener();
    };
  }, [dispatch]);
  return uid;
};
