import React from "react";
import { useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { themeVal } from "../../design/themeVal";
import { useImageUrl } from "okthink-core/logic/useImageUrl";
import { getItem } from "okthink-core/logic/warpathSlice";
import { isItemImportant } from "okthink-core/logic/warpath/isItemImportant";
import { COLORS } from "okthink-core";
import { isItemFun } from "okthink-core/logic/warpath/isItemFun";
import { getTimeOfDayBorder } from "okthink-core/logic/getTimeOfDayBorder";
import {
  TodoItemIcon,
  BOX,
  PUNT,
  DONE,
  BULLET,
} from "../../components/TodoItemIcon";
import { Editor } from "../../components/Editor";
import { createSelector } from "@reduxjs/toolkit";
import { TagEmoji } from "../../components/TagEmoji";
import { HabitLabel } from "../Settings/HabitLabel";
import { HabitStreak } from "../Settings/HabitStreak";
import { getCurrentDateKey } from "okthink-core/logic/timelineSlice";
import { Tag } from "./Tag";
import { highlightTagsAndUrls } from "okthink-core/logic/highlightTagsAndUrls";

const doneTheme = {
  backgroundColor: "transparent",
  borderColor: "transparent",
  labelColor: "#e7deef",
};

const todoTheme = {
  backgroundColor: "rgba(200, 255, 200, .05)",
  borderColor: "transparent",
};

const importantTheme = {
  backgroundColor: COLORS.BASE.value,
  labelColor: "#f5f5f5",
  fontWeight: "bold",
  componentExtra: {
    backgroundColor: COLORS.IMPORTANT_ITEM.value,
  },
};

const funTheme = {
  backgroundColor: COLORS.GREEN.value,
  labelColor: "#f5f5f5",
  fontWeight: "bold",
  componentExtra: {
    backgroundColor: "#315614",
  },
};

const noteTheme = {
  labelColor: COLORS.WHITE.value,
};

const habitTheme = {
  backgroundColor: "rgba(200, 255, 200, .05)",
};

const Component = styled.div`
  scroll-margin: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${themeVal("padding", "5px")};
  background-color: ${themeVal("backgroundColor", "transparent")};
  font-style: ${themeVal("fontStyle", "default")};
  border: 1px solid ${themeVal("borderColor", "transparent")};
  border-radius: 2px;
  font-size: 14px;
  transition: background-color 0.3s;
  opacity: ${themeVal("opacity", 1)};
  ${(props) => props.theme.componentExtra}
  ${(props) =>
    props.leftBorderColor && {
      borderLeft: `1px solid ${props.leftBorderColor}`,
    }}
  ${(props) =>
    props.isActive && {
      borderColor: COLORS.ACTIVE_ITEM_BORDER.value,
    }}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 12px;
  gap: 2px;
  margin-left: 2px;
`;

const Header = styled.div`
  display: flex;
  margin-left: 20px;
  gap: 5px;
  align-items: center;
`;

const DebugId = styled.div`
  font-size: 10px;
  color: ${(props) =>
    props.done ? COLORS.PURPLER.value : COLORS.PURPLE.value};
  display: none;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  font-weight: ${themeVal("fontWeight", "default")};
`;

const Image = styled.img`
  max-height: 200px;
  width: 100%;
  border-radius: 2px;
  object-fit: contain;
  margin-top: 5px;
  opacity: ${(props) => (props.loading ? ".5" : "1")};
`;

const Label = styled.span`
  display: inline-block;
  padding: 0;
  line-height: 20px;
  color: ${(props) => props.color};
  text-decoration: ${(props) =>
    props.strikeThrough ? "line-through" : "default"};

  white-space: pre-wrap;
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : "inherit")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "inherit")};
  padding-left: 2px;
`;

const Spacer = styled.div`
  min-width: 20px;
  margin-left: 6px;
`;

const Link = styled.a`
  color: ${COLORS.LINK.value};
`;

const selectItemData = createSelector(
  [
    (state) => {
      const { tagColorMap } = state.notebook;
      return tagColorMap;
    },
    (state) => {
      const { habitsMap } = state.notebook;
      return habitsMap;
    },
    (state, taskListId, itemId) => {
      const item = getItem(state.warpath, taskListId, itemId);
      return item;
    },
    (state) => {
      return getCurrentDateKey(state.timeline);
    },
  ],
  (tagColorMap, habitsMap, item, dateKey) => {
    const habit = item.habitId ? habitsMap[item.habitId] : null;
    return { tagColorMap, item, habit, dateKey };
  },
  {
    memoizeOptions: {
      maxSize: 1000,
    },
  }
);

export const TodoItem = React.forwardRef(
  ({ taskListId, itemId, isActive, editing }, ref) => {
    const itemData = useSelector((state) => {
      return selectItemData(state, taskListId, itemId);
    });

    const { tagColorMap, item, habit, dateKey } = itemData;

    const { punt, value: done, tags = [] } = item;

    let combinedTags = tags;
    if (habit && habit.tags) {
      combinedTags = [...habit.tags, ...tags];
    }

    const important = isItemImportant(item);
    const fun = isItemFun(item);

    let theme = todoTheme;
    if (important) {
      theme = importantTheme;
    } else if (habit) {
      theme = habitTheme;
    } else if (fun) {
      theme = funTheme;
    } else if (!item.todo) {
      theme = noteTheme;
    }

    if (done || punt) {
      theme = {
        ...theme,
        ...doneTheme,
      };
    }

    const leftBorderColor = !isActive && getTimeOfDayBorder(combinedTags);

    const itemTextColor = theme.labelColor || COLORS.WHITE.value;
    const fontFamily = theme.fontFamily || "inherit";

    const getIcon = () => {
      if (item.punt) {
        return PUNT;
      } else if (item.todo && item.value) {
        return DONE;
      } else if (item.todo && !item.value) {
        return BOX;
      } else {
        return BULLET;
      }
    };

    const imageUrl = item.images ? item.images[0] : null;
    const showImage = imageUrl || item.meta.uploadingImage;
    const image = useImageUrl(imageUrl); // I don't like that this isn't conditional

    const { uploadingImage, uploadPreviewFile } = item.meta;

    const imageSrc = uploadingImage
      ? URL.createObjectURL(uploadPreviewFile)
      : image;

    const renderHabitText = () => {
      if (!habit) {
        return null;
      }

      return (
        <HabitLabel
          tags={tags}
          tagColorMap={tagColorMap}
          text={habit.text}
          color={itemTextColor}
        />
      );
    };

    const renderItemText = () => {
      if (!item.text) {
        return null;
      }

      const itemTextHighlighted = highlightTagsAndUrls(
        item.text,

        (tag) => {
          return <Tag tag={tag} key={tag} />;
        },
        (url) => {
          return (
            <Link key={url} href={url} target="_blank" rel="noreferrer">
              {url}
            </Link>
          );
        }
      );

      return (
        <Label
          strikeThrough={false}
          color={itemTextColor}
          fontFamily={fontFamily}
        >
          {itemTextHighlighted}
        </Label>
      );
    };

    return (
      <ThemeProvider theme={theme}>
        <Component ref={ref}>
          <Item leftBorderColor={leftBorderColor} isActive={isActive}>
            <Header>
              <DebugId done={done}>{item.meta.id}</DebugId>
            </Header>
            <Body>
              <TodoItemIcon icon={getIcon()} recurring={!!habit} />
              <Content>
                {renderHabitText()}
                {editing ? <Editor /> : renderItemText()}

                {showImage && <Image src={imageSrc} loading={uploadingImage} />}
              </Content>
              {habit && (
                <HabitStreak habitId={habit.meta.id} startDateKey={dateKey} />
              )}
            </Body>
          </Item>
          <Spacer>
            <TagEmoji tags={combinedTags} />
          </Spacer>
        </Component>
      </ThemeProvider>
    );
  }
);
