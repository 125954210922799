import { getDocs } from "firebase/firestore";
import { isDirty } from "../logic/warpath/isDirty";
import {
  getTaskListById,
  isTempId,
  taskListSynced,
} from "../logic/warpathSlice";

import {
  getItemRef,
  getItemsQuery,
  getTaskListRef,
  startWriteBatch,
} from "../data/firebase-utils";
import { checkForTagsSync } from "./checkForTagsSync";

import { checkForItemDoc } from "./checkForItemDoc";
import { filterUndefined } from "../logic/filterUndefined";
import { checkForTaskListDoc } from "./checkForTaskListDoc";
import { getBacklogTaskListId } from "../logic/notebook/notebookSlice";

export const syncTaskListV2 = async ({ store, taskListId, uid }) => {
  try {
    console.log("--syncTaskListV2--", taskListId);
    const safeTaskListId = checkForTaskListDoc(store, uid, taskListId);

    const reduxTaskList = getTaskListById(
      store.getState().warpath,
      safeTaskListId
    );

    const batch = startWriteBatch();

    // sync the items.
    for (const itemId of reduxTaskList.order) {
      const item = reduxTaskList.itemsMap[itemId];
      if (isTempId(itemId) || isDirty(item)) {
        const safeItemId = checkForItemDoc(store, uid, safeTaskListId, itemId);
        const docRef = getItemRef(uid, safeTaskListId, safeItemId);

        const { meta, ...itemData } = reduxTaskList.itemsMap[itemId];
        batch.set(docRef, itemData, { merge: true });
      }
    }

    // get the taskList again with updatedIds
    const updatedTaskList = getTaskListById(
      store.getState().warpath,
      safeTaskListId
    );

    // delete docs not in the order array
    const itemsQuery = getItemsQuery(uid, safeTaskListId);
    const itemSnapshots = await getDocs(itemsQuery);
    itemSnapshots.forEach((itemDoc) => {
      if (!updatedTaskList.order.includes(itemDoc.id)) {
        batch.delete(itemDoc.ref);
      }
    });

    const isBacklog =
      taskListId === getBacklogTaskListId(store.getState().notebook);

    const { meta, itemsMap, ...taskListUpdateData } = updatedTaskList;
    const safeData = filterUndefined(taskListUpdateData);

    // make sure the dateKey isn't saved for the backlog, it ruins queries
    if (isBacklog) {
      delete safeData.dateKey;
    }

    const taskListRef = getTaskListRef(uid, safeTaskListId);
    batch.set(taskListRef, safeData, { merge: true });

    await batch.commit();

    store.dispatch(taskListSynced({ taskListId: safeTaskListId }));

    checkForTagsSync(uid, store);
  } catch (e) {
    console.log(e);
    console.error("syncTaskListV2 error", e);
    debugger;
  }
};
