import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forecast: null,
};

export const weatherSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {
    setWeatherForecast: (state, action) => {
      const forecast = action.payload;
      state.forecast = forecast;
    },
  },
});

export const { setWeatherForecast } = weatherSlice.actions;

export const weatherReducer = weatherSlice.reducer;
