import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { CheatShowcase } from "./CheatShowcase";
import { LogoText, H1, H2, H3, H4, Text, Subtext } from "../design/type";

const Component = styled.div`
  background-color: ${COLORS.BASE2.value};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Column = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const testString = "Fun with okthink";

export const TypeCheat = () => {
  return (
    <Component>
      <Column>
        <CheatShowcase label="text logo">
          <LogoText>okthink..</LogoText>
        </CheatShowcase>
        <CheatShowcase label="h1/title">
          <H1>{testString}</H1>
        </CheatShowcase>
        <CheatShowcase label="h2">
          <H2>{testString}</H2>
        </CheatShowcase>
        <CheatShowcase label="h3">
          <H3>{testString}</H3>
        </CheatShowcase>
        <CheatShowcase label="h4">
          <H4>{testString}</H4>
        </CheatShowcase>
        <CheatShowcase label="base text">
          <Text>{testString}</Text>
        </CheatShowcase>
        <CheatShowcase label="subtext">
          <Subtext>{testString}</Subtext>
        </CheatShowcase>
      </Column>
    </Component>
  );
};
