import { getHabit } from "./notebook/notebookSlice";
import { store } from "./store";
import uniq from "lodash/uniq";

export const getItemAndHabitTags = (item) => {
  const { tags = [] } = item;
  let returnTags = [...tags];

  if (item.habitId) {
    const habit = getHabit(store.getState().notebook, item.habitId);
    const { tags: habitTags = [] } = habit;
    returnTags.push(...habitTags);
  }

  const uniqueTags = uniq(returnTags);
  return uniqueTags;
};
