import { setDoc, updateDoc } from "firebase/firestore";
import { getNotebookRef, getHabitRef } from "../data/firebase-utils";
import { syncDirtyItem } from "./syncDirtyItem";
import { syncDirtyTaskLists } from "./syncDirtyTaskLists";
import { syncItemDoc } from "./syncItemDoc";
import { checkForTagsSync } from "./checkForTagsSync";

const ACTION_COMMIT = "warpath/commit";

const ACTION_MOVE_ITEM_BEFORE = "warpath/moveItemBefore";
const ACTION_MOVE_ITEM_AFTER = "warpath/moveItemAfter";

const ACTION_SAFE_DELETE_BY_ITEM_ID = "warpath/safeDeleteByItemId";
const ACTION_REPLACE_ID = "warpath/replaceId";
const ACTION_TOGGLE_ITEM = "warpath/toggleItem";
const ACTION_TOGGLE_ITEM_TYPE = "warpath/toggleItemType";
const ACTION_SORT_TASK_LIST = "warpath/sortTaskList";
const ACTION_MOVE_ITEM_TO_TASK_LIST = "warpath/moveItemToTaskList";
const ACTION_ITEM_IMAGE_UPLOAD_COMPLETED = "warpath/itemImageUploadCompleted";
const ACTION_DELETE_ITEM_ATTACHMENT = "warpath/deleteItemAttachment";
const ACTION_MOVE_ITEM_TO_TOMORROW = "warpath/moveItemToTomorrow";
const ACTION_TOGGLE_PUNT_ITEM = "warpath/togglePuntItem";

const ACTION_HABIT_COMMIT = "notebook/commitHabitText";
const ACTION_HABIT_ARCHIVE = "notebook/archiveHabit";
const ACTION_HABIT_UNARCHIVE = "notebook/unarchiveHabit";
const ACTION_HABIT_MOVE_UP = "notebook/moveHabitUp";
const ACTION_HABIT_MOVE_DOWN = "notebook/moveHabitDown";
const ACTION_HABIT_COMPLETION = "notebook/setHabitCompletion";

const log = (...items) => {
  console.log(`[Firebase Middleware]`, ...items);
};

const syncHabitsOrder = async (uid, store) => {
  const notebookRef = getNotebookRef(uid);
  const { habitsOrder, archivedHabits } = store.getState().notebook;
  setDoc(
    notebookRef,
    {
      habitsOrder,
      archivedHabits,
    },
    { merge: true }
  );
};

export const syncMiddleware = (store) => (next) => (action) => {
  const { type, payload } = action;
  const { uid } = store.getState().user;
  switch (type) {
    case ACTION_COMMIT:
    case ACTION_SORT_TASK_LIST:
    case ACTION_REPLACE_ID:
    case ACTION_MOVE_ITEM_AFTER:
    case ACTION_MOVE_ITEM_BEFORE:
    case ACTION_SAFE_DELETE_BY_ITEM_ID:
    case ACTION_MOVE_ITEM_TO_TASK_LIST:
    case ACTION_MOVE_ITEM_TO_TOMORROW:
    case ACTION_TOGGLE_ITEM:
    case ACTION_TOGGLE_ITEM_TYPE:
    case ACTION_DELETE_ITEM_ATTACHMENT:
    case ACTION_TOGGLE_PUNT_ITEM:
    case ACTION_ITEM_IMAGE_UPLOAD_COMPLETED: {
      log(type, payload);
      const nextVal = next(action);
      syncDirtyTaskLists(store, uid);
      return nextVal;
    }
    case ACTION_HABIT_COMMIT: {
      const nextVal = next(action);
      const { habitId, value, tags, newId } = action.payload;
      const docId = newId ? newId : habitId;
      const habitRef = getHabitRef(uid, docId);
      // make sure it also has a completions map
      const extra = newId ? { completions: {} } : null;
      setDoc(
        habitRef,
        {
          text: value,
          tags,
          ...extra,
        },
        { merge: true }
      );

      if (newId) {
        // if there was a replacement id, then we also need to save the order
        syncHabitsOrder(uid, store);
      }

      checkForTagsSync(uid, store);

      return nextVal;
    }
    case ACTION_HABIT_ARCHIVE: {
      const nextVal = next(action);
      const { habitId } = action.payload;
      const habitRef = getHabitRef(uid, habitId);
      console.log("archiving", habitRef.path);
      setDoc(
        habitRef,
        {
          archived: true,
        },
        { merge: true }
      );
      syncHabitsOrder(uid, store);

      return nextVal;
    }

    case ACTION_HABIT_UNARCHIVE: {
      const nextVal = next(action);
      const { habitId } = action.payload;
      const habitRef = getHabitRef(uid, habitId);
      console.log("unarchiving", habitRef.path);
      setDoc(
        habitRef,
        {
          archived: false,
        },
        { merge: true }
      );
      syncHabitsOrder(uid, store);

      return nextVal;
    }
    case ACTION_HABIT_MOVE_UP:
    case ACTION_HABIT_MOVE_DOWN: {
      const nextVal = next(action);
      syncHabitsOrder(uid, store);
      return nextVal;
    }
    case ACTION_HABIT_COMPLETION: {
      const nextVal = next(action);
      const { habitId, dateKey, nextValue, modified } = action.payload;

      // get habitRef
      const habitRef = getHabitRef(uid, habitId);

      updateDoc(habitRef, {
        [`completions.${dateKey}`]: {
          done: nextValue,
          modified,
        },
      });

      return nextVal;
    }

    default:
      return next(action);
  }
};
