import dayjs from "dayjs";
import range from "lodash/range";

export const DATEKEY_FORMAT = "YYYY-MM-DD";

export const getDateKey = (day) => {
  return day.format(DATEKEY_FORMAT);
};

export const getTodaysDateKey = () => {
  const today = dayjs();
  return getDateKey(today);
};

export const dayjsFromDateKey = (dateKey) => {
  return dayjs(dateKey, DATEKEY_FORMAT);
};

export const dateKeyRange = (startDateKey, rangeStart, rangeEnd) => {
  // if rangeEnd is undefined, range from 0 to rangeStart.
  if (rangeEnd === undefined) {
    rangeEnd = rangeStart;
    rangeStart = 0;
  }

  const steps = range(rangeStart, rangeEnd);
  const startDay = dayjsFromDateKey(startDateKey);

  const dateKeys = steps.map((delta) => {
    const day = startDay.add(delta, "days");
    return getDateKey(day);
  });

  return dateKeys;
};
