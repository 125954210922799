import { setDoc } from "./backend";

import { getItem, itemSynced } from "../logic/warpathSlice";
import { getItemRef } from "../data/firebase-utils";

export const syncDirtyItem = async ({ store, uid, taskListId, itemId }) => {
  const fullItem = getItem(store.getState().warpath, taskListId, itemId);
  const { meta, ...item } = fullItem;
  const docRef = getItemRef(uid, taskListId, itemId);
  await setDoc(docRef, item, { merge: true });
  store.dispatch(itemSynced({ taskListId, itemId }));
};
