import { useSelector } from "react-redux";

export const HelpLevel = ({ children, level }) => {
  const helpLevel = useSelector((state) => {
    const { workspace } = state;
    return workspace.viewOptions.helpLevel;
  });

  if (helpLevel === 0) {
    return null;
  }

  return children;
};
