import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { getWeekKey } from "okthink-core/logic/getWeekKey";
import { useImageUrl } from "okthink-core/logic/useImageUrl";
import { getCurrentDateKey } from "okthink-core/logic/timelineSlice";

import { setWeekBackground } from "okthink-core/logic/notebook/notebookSlice";
import { syncWeekBackground } from "okthink-core/data/loaders";
import { uploadImage } from "okthink-core/data/files";
import { COLORS } from "okthink-core";
import { getSelectedDateKey } from "okthink-core/logic/timeline/getSelectedDateKey";
import { useAuthState } from "okthink-core/data/auth";

const getDropzoneStyle = (props) => {
  let style = { backgroundColor: "transparent" };

  if (props.isDragAccept) {
    style = {
      backgroundColor: COLORS.GREEN.value,
    };
  }
  if (props.isDragReject) {
    style = {
      backgroundColor: COLORS.RED.value,
    };
  }

  return style;
};

const Component = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:focus {
    outline: none;
  }
  transition: background-color 0.5s ease-in-out;
  ${getDropzoneStyle}
`;

const getOpacity = (props) => {
  if (props.isDragging) {
    return 0.5;
  } else if (props.loaded) {
    return 1;
  }
  return 0;
};

const Image = styled.div`
  background-image: url("${(props) => props.backgroundImg}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  opacity: ${getOpacity};
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const WeeklyBackground = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [localImage, setLocalImage] = useState(null);

  const dateKey = useSelector((state) => {
    return getSelectedDateKey(state.timeline);
  });

  const uid = useAuthState();

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: "image/*",
      noClick: true,
      onDrop: (acceptedFiles) => {
        // upload the file here and write it to db.
        const sideEffect = async () => {
          const imageFile = acceptedFiles[0];
          // get the actual image
          const reader = new FileReader();
          reader.onload = () => {
            setLocalImage(reader.result);
          };
          reader.readAsDataURL(acceptedFiles[0]);

          const weekKey = getWeekKey(dateKey);
          const { path } = await uploadImage({
            uid,
            imageFile,
          });
          await syncWeekBackground({ uid, weekKey, path });
          dispatch(setWeekBackground({ weekKey, path }));
          setLocalImage(null);
        };

        sideEffect();
      },
    });

  const { backgroundPath } = useSelector((state) => {
    const dateKey = getCurrentDateKey(state.timeline);
    const weekKey = getWeekKey(dateKey);
    const { weeklyBackgrounds = {} } = state.notebook;
    const backgroundPath = weeklyBackgrounds[weekKey];
    return { backgroundPath };
  });

  useEffect(() => {
    setLoaded(false);
  }, [backgroundPath]);

  const cloudImage = useImageUrl(backgroundPath);
  const imageToUse = localImage || cloudImage;

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <Component {...getRootProps({ isDragAccept, isDragReject })}>
      <img
        src={cloudImage}
        alt={"decoration"}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
      <Image
        loaded={loaded}
        backgroundImg={imageToUse}
        isDragging={isDragAccept || isDragReject}
      />
      <input {...getInputProps()} />
    </Component>
  );
};
