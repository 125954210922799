import React from "react";
import styled from "styled-components";
import { COLORS } from "okthink-core";
import { useSelector } from "react-redux";
import { KeyValue } from "./KeyValue";
import { TaskListData } from "./TaskListData";
import { Text } from "../design/type";

const Component = styled.div`
  flex: 1;
  display: flex;
  padding: 5px;
  background-color: ${COLORS.BASE.value};
  gap: 3px;
`;
const DisplayArea = styled.div`
  padding: 2px;
  background-color: ${COLORS.BASE2.value};
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: ${(props) => (props.width ? "default" : 1)};
  width: ${(props) => props.width || "default"};
`;

const TaskListContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const WarpathCheat = () => {
  const warpathData = useSelector((state) => state.warpath);
  const keys = [
    "cursor",
    "keyStrokes",
    "history",
    "columns",
    "dateKeys",
    "viewOptions",
  ];
  const { taskLists } = warpathData;

  const taskListKeys = Object.keys(taskLists);

  return (
    <Component>
      <DisplayArea width={300}>
        {keys.map((key) => {
          return <KeyValue label={key} value={warpathData[key]} />;
        })}
      </DisplayArea>
      <DisplayArea>
        <Text>TaskLists</Text>
        <TaskListContainer>
          {taskListKeys.map((key) => {
            return <TaskListData label={key} taskList={taskLists[key]} />;
          })}
        </TaskListContainer>
      </DisplayArea>
    </Component>
  );
};
