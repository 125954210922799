import { getNewHabitId } from "../data/firebase-utils";
import { getTags } from "./getTags";
import { getHabitsOrder } from "./notebook/getHabitsOrder";
import {
  insertHabitAfter,
  moveHabitDown,
  moveHabitUp,
  commitHabitText,
  clearDraftHabit,
  NEW_HABIT_DRAFT,
  archiveHabit,
  unarchiveHabit,
  addNewHabit,
} from "./notebook/notebookSlice";
import { setHabitIds } from "./warpathSlice";
import {
  cursorDown,
  cursorDownAndEditing,
  cursorUp,
  cursorY,
  setEditing,
} from "./workspaceSlice";

const updateDefaultItems = (store) => {
  const habitIds = getHabitsOrder(store.getState().notebook);
  store.dispatch(setHabitIds({ habitIds }));
};

export const HabitsDelegate = (store) => {
  return {
    insertAtCursor: () => {
      console.log("unsupported");
    },
    moveItemUp: ({ itemId: habitId }) => {
      store.dispatch(moveHabitUp({ habitId }));
      store.dispatch(cursorUp());
      updateDefaultItems(store);
    },
    moveItemDown: ({ itemId: habitId, maxY }) => {
      store.dispatch(moveHabitDown({ habitId }));
      store.dispatch(cursorDown({ maxY }));
      updateDefaultItems(store);
    },
    insertAfter: ({ y, uid }) => {
      const { notebook } = store.getState();
      const { habitsOrder } = notebook;
      const safeY = Math.min(habitsOrder.length - 1, y);
      const habitId = habitsOrder[safeY];

      if (habitsOrder.length === 0) {
        store.dispatch(addNewHabit());
        store.dispatch(cursorY({ y: 0 }));
        store.dispatch(setEditing(true));
      } else {
        store.dispatch(insertHabitAfter({ habitId, uid }));
        store.dispatch(cursorDownAndEditing({ maxY: safeY + 1 }));
      }

      updateDefaultItems(store);
    },
    toggleItem: (badTaskListId, itemId) => {
      console.log("unsupported");
    },
    deleteItem: ({ itemId }) => {
      const { notebook } = store.getState();
      const { archivedHabits } = notebook;
      if (archivedHabits.includes(itemId)) {
        store.dispatch(unarchiveHabit({ habitId: itemId }));
      } else {
        store.dispatch(archiveHabit({ habitId: itemId }));
      }
      updateDefaultItems(store);
    },
    getColumnSize: () => {
      const { notebook } = store.getState();
      const { habitsOrder, archivedHabits } = notebook;
      return habitsOrder.length + archivedHabits.length;
    },
    getItemAt: (columnId, y) => {
      const { notebook } = store.getState();
      const { habitsOrder, archivedHabits, habitsMap } = notebook;

      const orderLength = habitsOrder.length;

      if (y > orderLength - 1) {
        const index = y - orderLength;
        const habitId = archivedHabits[index];
        const habit = habitsMap[habitId];
        return { itemId: habitId, item: habit };
      }

      const habitId = habitsOrder[y];
      const habit = habitsMap[habitId];
      return { itemId: habitId, item: habit };
    },
    getColumn: (columnId) => {
      return null;
    },
    puntItem: (badTaskListId, itemId) => {},
    sort: (taskListId) => {},
    getTextValue: (habit) => {
      return habit.text;
    },
    commit: ({ itemId, newValue, uid }) => {
      if (newValue.trim() === "") {
        store.dispatch(clearDraftHabit());
      } else {
        const newId = itemId === NEW_HABIT_DRAFT ? getNewHabitId(uid) : null;

        const tags = getTags(newValue);
        store.dispatch(
          commitHabitText({
            habitId: itemId,
            value: newValue,
            tags,
            newId,
          })
        );
        // update default items in warpath
        if (itemId === NEW_HABIT_DRAFT) {
          updateDefaultItems(store);
        }
      }
    },
  };
};
