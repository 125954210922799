import React from "react";
import styled from "styled-components";
import { Text } from "../design/type";
import { COLORS } from "okthink-core";
import { KeyValue } from "./KeyValue";

const Component = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px;
  gap: 1px;
  border-radius: 1px;
  background-color: ${COLORS.BASE3.value};
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TaskListData = ({ label, taskList }) => {
  const { meta, itemsMap, order } = taskList;

  return (
    <Component>
      <Text>{label}</Text>
      <Container>
        <KeyValue label="meta" value={meta} />
        <KeyValue label="order" value={order} />
        <KeyValue label="itemsMap" value={itemsMap} />
      </Container>
    </Component>
  );
};
