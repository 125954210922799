import React from "react";
import styled from "styled-components";
import { ReduxDisplay } from "./ReduxDisplay";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: display;
`;

export const ReduxCheat = () => {
  return (
    <Component>
      <ReduxDisplay label="warpath" />
      <ReduxDisplay label="workspace" />
    </Component>
  );
};
