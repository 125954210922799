import {
  getTaskListByDateKey,
  setTaskList,
  addNewTaskList,
} from "../logic/warpathSlice";
import { reqTaskListAtDateKey } from "../data/loaders";

export const ensureTaskListAtDateKey = async ({ store, dateKey }) => {
  let { user, warpath } = store.getState();

  const { uid } = user;

  // first check if we already have something loaded
  let taskListId = getTaskListByDateKey(warpath, dateKey);
  if (taskListId) {
    return taskListId;
  }

  // then check if we just haven't loaded it.
  const taskList = await reqTaskListAtDateKey(uid, dateKey);
  if (taskList) {
    store.dispatch(setTaskList(taskList));
    return taskList.meta.id;
  }

  // there isn't a taskList, so add a temporary one
  store.dispatch(addNewTaskList({ dateKey }));

  taskListId = getTaskListByDateKey(store.getState().warpath, dateKey);
  return taskListId;
};
