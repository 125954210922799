import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Editor } from "../../components/Editor";
import { TagEmoji } from "../../components/TagEmoji";
import { COLORS } from "okthink-core";
import { DebugLabel } from "../../dev/DebugLabel";
import { getSelectedDateKey } from "okthink-core/logic/timeline/getSelectedDateKey";
import { HabitLabel } from "./HabitLabel";
import { HabitStreak } from "./HabitStreak";
import { getTimeOfDayBorder } from "okthink-core/logic/getTimeOfDayBorder";

const Component = styled.div`
  scroll-margin: 200px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: rgba(200, 255, 200, 0.05);
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 14px;
  transition: background-color 0.3s;
  font-size: 12px;
  ${(props) =>
    props.leftBorderColor && {
      borderLeft: `1px solid ${props.leftBorderColor}`,
    }}
  ${(props) =>
    props.isActive && {
      borderColor: COLORS.ACTIVE_ITEM_BORDER.value,
    }}
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2px;
`;

const selectHabitData = createSelector(
  [
    (state, habitId) => {
      return state.notebook.habitsMap[habitId];
    },
    (state) => {
      return getSelectedDateKey(state.timeline);
    },
    (state) => {
      const { tagColorMap } = state.notebook;
      return tagColorMap;
    },
  ],
  (habit, dateKey, tagColorMap) => {
    return { habit, dateKey, tagColorMap };
  }
);

export const HabitItem = React.forwardRef(
  ({ habitId, isActive, editing }, ref) => {
    const { habit, dateKey, tagColorMap } = useSelector((state) => {
      return selectHabitData(state, habitId);
    });

    const { text, tags, meta } = habit;

    const debugId = meta ? meta.id : "editing";

    const leftBorderColor = !isActive && getTimeOfDayBorder(tags);

    return (
      <Component
        isActive={isActive}
        ref={ref}
        leftBorderColor={leftBorderColor}
      >
        <DebugLabel text={debugId} />
        <Body>
          <Content>
            {editing ? (
              <Editor />
            ) : (
              <HabitLabel text={text} tags={tags} tagColorMap={tagColorMap} />
            )}
          </Content>
          <TagEmoji tags={tags} />
          <HabitStreak habitId={habitId} startDateKey={dateKey} />
        </Body>
      </Component>
    );
  }
);
