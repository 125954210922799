import styled, { keyframes } from "styled-components";
import { COLORS } from "okthink-core";

export const LogoText = styled.div`
  font-family: "Oldenburg";
  font-size: 45px;
  color: ${COLORS.WHITE.value};
`;

export const ShowcaseText = styled.div`
  font-family: Work Sans;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.PINK.value};
`;

export const H1 = styled.h1`
  font-size: 40px;
  margin: 0;
  margin-bottom: 40px;
  color: ${COLORS.WHITE.value};
`;
export const H2 = styled.h2`
  font-family: "Work Sans";
  font-size: 32px;
  font-weight: 600;
  color: ${COLORS.WHITE.value};
`;
export const H3 = styled.h3`
  margin: 0;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  color: ${COLORS.WHITE.value};
`;

export const H4 = styled.h3`
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  margin: 0px;
  color: ${COLORS.WHITE.value};
`;

export const OldText = styled.div`
  font-family: "Work Sans";
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.color || COLORS.WHITE.value};
`;

export const Text = styled.div`
  font-family: "Work Sans";
  font-size: 12px;
  color: ${(props) => props.color || COLORS.WHITE.value};
`;

export const TagText = styled.span`
  font-family: "Work Sans";
  font-size: 12px;
`;

export const Label = styled.div`
  font-family: "Work Sans";
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS.WHITE.value};
`;

export const MicroLabel = styled.div`
  font-family: "Work Sans";
  font-size: 12px;
  line-height: 12px;
  color: ${COLORS.WHITE.value};
`;

export const Subtext = styled.div`
  font-family: "Work Sans";
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: ${COLORS.WHITE.value};
`;

export const SectionHeader = styled(H2)`
  font-family: "Lustria", serif;
  font-size: 16px;
  color: ${COLORS.PANEL_HEADER.value};
  margin-bottom: 8px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 16)}px;
`;

export const ColorName = styled.div`
  font-family: "Roboto Mono";
  font-size: 10px;
  font-weight: bold;
  color: ${COLORS.WHITE.value};
  border-radius: 2px;
  margin: 2px 0px;
  opacity: 0.7;
`;

export const ColorHex = styled.div`
  font-family: "Roboto Mono";
  font-size: 8px;
  color: ${COLORS.WHITE.value};
  opacity: 0.5;
  margin: 2px 0px;
`;

export const ColorDescription = styled.div`
  font-family: "Roboto Mono";
  font-size: 8px;
  color: ${COLORS.WHITE.value};
  margin: 2px 0px;
  opacity: 0.4;
`;

const slideInBounceFadeIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  60% {
    transform: translateY(-20%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const ColorCopied = styled.div`
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.BASE.value};
  margin: 2px 0px;
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.5);
  animation: ${slideInBounceFadeIn} 0.3s ease-in-out;
`;
