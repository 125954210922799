import {
  getCurrentDateKey,
  getSelectedDay,
  goLeft,
  goRight,
  goToday,
} from "../logic/timelineSlice";
import { WarpathDelegate } from "./warpathDelegate";
import { IncompletesDelegate } from "./incompletesDelegate";

import {
  setSafeY,
  cursorLeft,
  cursorRight,
  cursorUp,
  cursorDown,
  cursorMove,
  setEditing,
  toggleEditing,
  pushKeyStroke,
  toggleHideComplete,
  clearKeyStrokes,
  toggleFocus,
  toggleShowSettings,
  setEditorDraft,
  clearEditorDraft,
  toggleShowDebug,
  moveColumnTagIndex,
  cycleHelpLevel,
  setColumnId,
  DATE_COLUMN_INDEX,
  toggleShowDevGuide,
} from "../logic/workspaceSlice";
import { HabitsDelegate } from "./habitsDelegate";

let store;

export const getColumnDelegate = (workspaceState) => {
  const {
    cursor,
    columns,
    viewOptions: { showSettings },
  } = workspaceState;

  if (showSettings) {
    return HabitsDelegate(store);
  }

  const { x } = cursor;
  const column = columns[x];
  if (column.type === "incompletes") {
    return IncompletesDelegate(store);
  }
  return WarpathDelegate(store);
};

// This function is going to need a lot of work.
// The settings screen is kind of a hack right now
// I'm going to have to figure out a good general workspace solution so that I don't need to have hacks like this.
//
export const getCursorContext = (state) => {
  const { workspace, timeline } = state;
  const { cursor, columns, viewOptions, editor } = workspace;
  const { focusMode, showSettings } = viewOptions;
  const uid = state.user && state.user.uid;
  const { x, y } = cursor;
  const column = columns[x];
  const columnDelegate = getColumnDelegate(workspace);
  const { columnId } = column;
  const { itemId, item } = columnDelegate.getItemAt(columnId, y, viewOptions);
  const dateKey = getCurrentDateKey(timeline);

  const cursorContext = {
    x,
    y,
    columnId,
    itemId,
    item,
    column,
    columns,
    columnDelegate,
    columnsLength: columns.length,
    uid,
    focusMode,
    editorDraft: editor.draft,
    dateKey,
  };

  if (showSettings) {
    cursorContext.columnId = "habits";
    cursorContext.column = null;
    cursorContext.columns = [];
    cursorContext.columnsLength = 0;
  }

  return cursorContext;
};

const withCursorContext = (fn) => {
  return () => {
    const cursorContext = getCursorContext(store.getState());
    fn(cursorContext);
  };
};

export const getCurrentItemId = (state) => {
  const { workspace } = state;
  const { cursor, columns } = workspace;
  const { x, y } = cursor;
  const column = columns[x];
  const columnDelegate = getColumnDelegate(workspace);
  const { activeId } = columnDelegate.getItemAt(column.columnId, y);
  return activeId;
};

const getColumnSizeForX = (x, state) => {
  const { workspace } = state;
  const { columns } = workspace;
  const column = columns[x];
  const columnDelegate = getColumnDelegate(workspace);
  return columnDelegate.getColumnSize(column.columnId);
};

export const keyBinds = {};

const keyBind = (stroke, description, command, category, helpKeys) => {
  keyBinds[stroke] = {
    stroke,
    description,
    command,
    category,
    helpKeys,
  };
};

const workspaceCursorLeft = () => {
  const { x, focusMode } = getCursorContext(store.getState());
  if (x > 0 && !focusMode) {
    const maxY = getColumnSizeForX(x - 1, store.getState()) - 1;
    store.dispatch(cursorLeft({ maxY }));
  }
};

const workspaceCursorRight = () => {
  const { x, columnsLength, focusMode } = getCursorContext(store.getState());
  if (x < columnsLength - 1 && !focusMode) {
    const maxY = getColumnSizeForX(x + 1, store.getState()) - 1;
    store.dispatch(cursorRight({ maxY }));
  }
};

const workspaceCursorUp = () => {
  store.dispatch(cursorUp());
};

const workspaceCursorDown = () => {
  const { columnId, columnDelegate } = getCursorContext(store.getState());
  const maxY = columnDelegate.getColumnSize(columnId) - 1;
  store.dispatch(cursorDown({ maxY }));
};

const workspaceEditItem = () => {
  const { item, columnDelegate } = getCursorContext(store.getState());
  const value = columnDelegate.getTextValue(item);
  store.dispatch(setEditorDraft({ value }));
  store.dispatch(setEditing(true));
};

const workspaceToggleEditing = () => {
  store.dispatch(toggleEditing());
};

const workspaceToggleComplete = () => {
  store.dispatch(toggleHideComplete());
  const { columnDelegate, columnId } = getCursorContext(store.getState());
  const maxY = columnDelegate.getColumnSize(columnId) - 1;
  console.log("maxY", maxY);
  store.dispatch(setSafeY({ maxY }));
};

const workspaceCycleHelp = () => {
  store.dispatch(cycleHelpLevel());
};

const workspaceToggleFocus = () => {
  store.dispatch(toggleFocus());
};

const workspaceToggleSettings = () => {
  store.dispatch(toggleShowSettings());
};

const workspaceToggleShowDebug = () => {
  store.dispatch(toggleShowDebug());
};

const workspaceToggleDevGuide = () => {
  store.dispatch(toggleShowDevGuide());
};

const commitDraft = withCursorContext((cursorContext) => {
  const { item, itemId, columnId, editorDraft, columnDelegate, dateKey, uid } =
    cursorContext;

  const currentValue = columnDelegate.getTextValue(item);

  columnDelegate.commit({
    item,
    itemId,
    columnId,
    dateKey,
    columnDelegate,
    newValue: editorDraft,
    currentValue,
    uid,
  });

  store.dispatch(setEditing(false));
  store.dispatch(clearEditorDraft());
});

const timelinePrevDay = () => {
  store.dispatch(goLeft());
  const state = store.getState();

  const day = getSelectedDay(state.timeline);
  const dateKey = day.format("YYYY-MM-DD");
  const dateKeyTaskListId = state.warpath.dateKeys[dateKey];

  if (dateKeyTaskListId) {
    store.dispatch(
      setColumnId({
        columnIndex: DATE_COLUMN_INDEX,
        columnId: dateKeyTaskListId,
      })
    );
  }
};
const timelineNextDay = () => {
  store.dispatch(goRight());
  const state = store.getState();

  const day = getSelectedDay(state.timeline);
  const dateKey = day.format("YYYY-MM-DD");
  const dateKeyTaskListId = state.warpath.dateKeys[dateKey];

  if (dateKeyTaskListId) {
    store.dispatch(
      setColumnId({
        columnIndex: DATE_COLUMN_INDEX,
        columnId: dateKeyTaskListId,
      })
    );
  }
};

const timelineToday = () => {
  store.dispatch(goToday());
};

const delegateNextTag = () => {
  const { columnDelegate, columnId: taskListId } = getCursorContext(
    store.getState()
  );

  const tagSummary = columnDelegate.getTagSummary(taskListId);
  const maxLength = tagSummary.length;
  store.dispatch(
    moveColumnTagIndex({ columnId: taskListId, maxLength, direction: 1 })
  );
};

const delegatePrevTag = () => {
  const { columnDelegate, columnId: taskListId } = getCursorContext(
    store.getState()
  );

  const tagSummary = columnDelegate.getTagSummary(taskListId);
  const maxLength = tagSummary.length;
  store.dispatch(
    moveColumnTagIndex({ columnId: taskListId, maxLength, direction: -1 })
  );
};

const delegateInsertBelow = () => {
  const { columnDelegate, columnId, y, uid, itemId } = getCursorContext(
    store.getState()
  );

  const text =
    (columnDelegate.getNewItemText &&
      columnDelegate.getNewItemText(columnId)) ||
    "";

  // warpathDelegate and habitsDelegate require different things.
  columnDelegate.insertAfter({ columnId, y, uid, itemId, text });
};
const delegateMoveItemUp = () => {
  const { columnDelegate, columnId, y, itemId } = getCursorContext(
    store.getState()
  );
  columnDelegate.moveItemUp({ columnId, y, itemId });
};

const delegateMoveItemDown = () => {
  const { columnDelegate, columnId, y, itemId } = getCursorContext(
    store.getState()
  );

  const maxY = columnDelegate.getColumnSize(columnId) - 1;
  columnDelegate.moveItemDown({ columnId, y, itemId, maxY });
};

const delegateMoveItemLeft = () => {
  const {
    columnDelegate,
    columnId: fromColumnId,
    itemId,
    x,
    y,
    columns,
  } = getCursorContext(store.getState());

  const toX = x - 1;
  if (toX < 0) {
    return;
  }

  const currentColumn = columns[x];
  const toColumn = columns[toX];
  if (toColumn.type === currentColumn.type) {
    const toColumnId = toColumn.columnId;
    const { newY } = columnDelegate.moveItem({
      itemId,
      y,
      toX,
      fromColumnId,
      toColumnId,
    });
    // dispatch the cursor to the new coordinate of the item
    //const newY = columnDelegate.getItemY(toColumnId, itemId);
    //store.dispatch(cursorPos({ x: x - 1, y: newY }));
    store.dispatch(cursorMove({ x: toX, y: newY }));
  }
};

const delegateMoveItemRight = () => {
  const {
    columnDelegate,
    columnId: fromColumnId,
    itemId,
    x,
    y,
    columns,
  } = getCursorContext(store.getState());

  const toX = x + 1;
  if (toX >= columns.length) {
    return;
  }

  const currentColumn = columns[x];
  const toColumn = columns[toX];
  if (toColumn.type === currentColumn.type) {
    const toColumnId = toColumn.columnId;
    const { newY } = columnDelegate.moveItem({
      itemId,
      y,
      toX,
      fromColumnId,
      toColumnId,
    });
    // dispatch the cursor to the new coordinate of the item
    //const newY = columnDelegate.getItemY(toColumnId, itemId);
    store.dispatch(cursorMove({ x: toX, y: newY }));
  }
};

const delegateToggleItemAsDone = () => {
  const { columnDelegate, columnId, itemId, item, dateKey } = getCursorContext(
    store.getState()
  );
  columnDelegate.toggleItem({ columnId, itemId, item, dateKey });
};

const delegateDeleteCurrentItem = () => {
  const { columnDelegate, columnId, itemId, item } = getCursorContext(
    store.getState()
  );
  columnDelegate.deleteItem({ columnId, itemId, item });
};

const delegateDeleteItemAttachment = () => {
  const { columnDelegate, columnId, itemId } = getCursorContext(
    store.getState()
  );
  columnDelegate.deleteItemAttachment(columnId, itemId);
};

const delegateSort = () => {
  const { columnDelegate, columnId } = getCursorContext(store.getState());
  if (columnDelegate.sort) {
    columnDelegate.sort(columnId);
  }
};

const delegateToggleItemType = () => {
  const { columnDelegate, columnId, itemId } = getCursorContext(
    store.getState()
  );
  columnDelegate.toggleItemType(columnId, itemId);
};

const delegateMoveItemToTomorrow = () => {
  const { columnDelegate, columnId, itemId } = getCursorContext(
    store.getState()
  );
  if (columnDelegate.moveItemToTomorrow) {
    columnDelegate.moveItemToTomorrow(columnId, itemId);
  }
};

const delegateMoveItemToBacklog = () => {
  const { columnDelegate, columnId, itemId } = getCursorContext(
    store.getState()
  );
  if (columnDelegate.moveItemToBacklog) {
    columnDelegate.moveItemToBacklog(columnId, itemId);
  }
};

const delegatePuntItem = () => {
  const { columnDelegate, columnId, itemId } = getCursorContext(
    store.getState()
  );
  if (columnDelegate.puntItem) {
    columnDelegate.puntItem(columnId, itemId);
  }
};

const MOVEMENT = "Movement";
const EDITING = "Editing";
const VIEW = "View";
const TIMELINE = "Timeline";

keyBind("h", "cursor left", workspaceCursorLeft, MOVEMENT);
keyBind("l", "cursor right", workspaceCursorRight, MOVEMENT);
keyBind("k", "cursor up", workspaceCursorUp, MOVEMENT);
keyBind("j", "cursor down", workspaceCursorDown, MOVEMENT);
keyBind("ff", "toggle focus mode", workspaceToggleFocus, VIEW, ["f", "f"]);

keyBind("`", "toggle settings", workspaceToggleSettings, VIEW);

keyBind("^", "toggle debug", workspaceToggleShowDebug, VIEW);
keyBind("#", "toggle dev guide", workspaceToggleDevGuide, VIEW);

keyBind("w", "go to prev tag", delegatePrevTag, VIEW);
keyBind("e", "go to next tag", delegateNextTag, VIEW);

keyBind("[", "go to previous day", timelinePrevDay, TIMELINE);
keyBind("]", "go to next day", timelineNextDay, TIMELINE);

keyBind("0", "go to today", timelineToday, TIMELINE);

keyBind("q", "hide/show complete", workspaceToggleComplete, VIEW);
keyBind("s", "sort", delegateSort, VIEW);
keyBind("?", "show/cycle keyboard help", workspaceCycleHelp, VIEW);

keyBind("i", "edit item", workspaceEditItem, EDITING);
keyBind("Enter", "toggle editing", workspaceToggleEditing, EDITING);
keyBind("o", "insert new item below", delegateInsertBelow, EDITING);
keyBind("ALT+˚", "move item up", delegateMoveItemUp, EDITING, [
  "ALT",
  "+",
  "k",
]);
keyBind("ALT+∆", "move item down", delegateMoveItemDown, EDITING, [
  "ALT",
  "+",
  "j",
]);
keyBind("ALT+˙", "move item left", delegateMoveItemLeft, EDITING, [
  "ALT",
  "+",
  "h",
]);
keyBind("ALT+¬", "move item right", delegateMoveItemRight, EDITING, [
  "ALT",
  "+",
  "l",
]);
keyBind("t", "toggle note/todo", delegateToggleItemType, EDITING);

keyBind("x", "toggle item as done", delegateToggleItemAsDone, EDITING);
keyBind("dd", "delete current item", delegateDeleteCurrentItem, EDITING, [
  "d",
  "d",
]);
keyBind("df", "delete item attachment", delegateDeleteItemAttachment, EDITING, [
  "d",
  "f",
]);

keyBind("mt", "move item to tomorrow", delegateMoveItemToTomorrow, EDITING, [
  "m",
  "t",
]);
keyBind("mb", "move item to backlog", delegateMoveItemToBacklog, EDITING, [
  "m",
  "b",
]);
keyBind("ALT+π", "punt item", delegatePuntItem, EDITING, ["ALT", "+", "p"]);

const handler = (event) => {
  const {
    cursor: { editing },
  } = store.getState().workspace;

  if (editing) {
    // I only care about the escape key
    if (event.key === "Escape") {
      commitDraft();
      event.preventDefault();
    }
    return;
  }

  const keyStroke = (event.altKey ? "ALT+" : "") + event.key;
  const prevStrokes = store.getState().workspace.keyStrokes;

  const twoStrokes = ["d", "m", "f"];

  if (twoStrokes.includes(keyStroke) && prevStrokes.length === 0) {
    store.dispatch(pushKeyStroke(keyStroke));
    return;
  }

  const keySequence = `${prevStrokes.join("")}${keyStroke}`;
  const keyBind = keyBinds[keySequence];

  if (keyBind) {
    keyBind.command();
    event.preventDefault();
  }

  store.dispatch(clearKeyStrokes());
};

const groupItems = (keyBinds) => {
  const categories = {};
  const shortcuts = Object.keys(keyBinds);
  shortcuts.forEach((shortcut) => {
    const keyBind = keyBinds[shortcut];
    const { category } = keyBind;
    if (!categories[category]) {
      categories[category] = [];
    }
    categories[category].push(keyBind);
  });

  return categories;
};

// possible hoisting issue?
export const keyGroups = groupItems(keyBinds);

export const KeyEngine = {
  setStore: (s) => {
    store = s;
  },
  getHandler: () => handler,
  handlePaste: (pasteEvent) => {
    const { clipboardData } = pasteEvent;
    if (clipboardData.files.length === 0) {
      return;
    }

    // consider the first item (can be easily extended for multiple items)
    var item = clipboardData.files[0];
    if (item.type.indexOf("image") === 0) {
      const { columnDelegate, columnId, itemId, uid } = getCursorContext(
        store.getState()
      );
      columnDelegate.handlePaste(columnId, itemId, uid, item);
    }
  },
};
