import React from "react";
import styled from "styled-components";
import { LogoText, H1, H2, H3, H4, Text, Subtext } from "../design/type";
import { Swatch } from "./Swatch";
import { Showcase } from "./Showcase";

import { GRAYS, BRIGHTS, COLORS } from "okthink-core";

const Component = styled.div`
  background-color: ${COLORS.BASE.value};
  display: flex;
  padding: 10px;
`;

const testString = "Fun with okthink";

const Column = styled.div`
  flex: 1;
`;

export const Design = () => {
  return (
    <Component>
      <Column>
        <Showcase label="text logo">
          <LogoText>okthink..</LogoText>
        </Showcase>
        <Showcase label="h1/title">
          <H1>{testString}</H1>
        </Showcase>
        <Showcase label="h2">
          <H2>{testString}</H2>
        </Showcase>
        <Showcase label="h3">
          <H3>{testString}</H3>
        </Showcase>
        <Showcase label="h4">
          <H4>{testString}</H4>
        </Showcase>
        <Showcase label="base text">
          <Text>{testString}</Text>
        </Showcase>
        <Showcase label="subtext">
          <Subtext>{testString}</Subtext>
        </Showcase>
      </Column>
      <Column>
        {GRAYS.map((color) => (
          <Swatch
            color={color.value}
            name={color.name}
            description={color.description}
          />
        ))}
      </Column>
      <Column>
        {BRIGHTS.map((color) => (
          <Swatch
            color={color.value}
            name={color.name}
            description={color.description}
          />
        ))}
      </Column>
    </Component>
  );
};
