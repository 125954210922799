import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { COLORS } from "okthink-core";
import { themeVal } from "../../design/themeVal";

const Component = styled.div`
  background-color: ${themeVal("backgroundColor")};
  margin: 5px;
  padding: 10px;
  transition: background-color 0.5s ease;
  border-radius: 2px;
  font-family: "Roboto Mono", monospace;
  color: ${COLORS.BASE.value};
`;

const THEMES = {
  missing: {
    backgroundColor: COLORS.YELLOW.value,
  },
  orphaned: {
    backgroundColor: COLORS.YELLOW.value,
  },
};

const JSONBlock = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
`;
export const ErrorItem = ({ missingId, orphanedItem }) => {
  const theme = missingId ? THEMES.missing : THEMES.orphaned;
  const body = missingId ? (
    <JSONBlock>item not found: {missingId}</JSONBlock>
  ) : (
    <JSONBlock>{JSON.stringify(orphanedItem, null, 2)}</JSONBlock>
  );

  return (
    <ThemeProvider theme={theme}>
      <Component>{body}</Component>
    </ThemeProvider>
  );
};
